import { Card, Col, Row, Space } from "antd";
import React from "react";
import { BidderRegistration } from "../../../../../generated/types";

type ShippingAddressProps = {
  bidderRegistration: BidderRegistration;
};

const ShippingAddress = ({ bidderRegistration }: ShippingAddressProps) => {
  return (
    <Card title="Shipping Address">
      <Space direction="vertical" size={16} style={{ width: "100%" }}>
        <Row gutter={[16, 8]}>
          <Col span={4}>
            <strong>Full Name:</strong>
          </Col>
          <Col span={20}>{bidderRegistration.shippingAddressFullName}</Col>

          <Col span={4}>
            <strong>Line 1:</strong>
          </Col>
          <Col span={8}>{bidderRegistration.shippingAddressLineOne}</Col>

          <Col span={4}>
            <strong>Line 2:</strong>
          </Col>
          <Col span={8}>{bidderRegistration.shippingAddressLineTwo}</Col>

          <Col span={4}>
            <strong>Line 3:</strong>
          </Col>
          <Col span={8}>{bidderRegistration.shippingAddressLineThree}</Col>

          <Col span={4}>
            <strong>City:</strong>
          </Col>
          <Col span={8}>{bidderRegistration.shippingAddressCity}</Col>

          <Col span={4}>
            <strong>State/Province/Region:</strong>
          </Col>
          <Col span={8}>
            {bidderRegistration.shippingAddressStateProvinceRegion}
          </Col>

          <Col span={4}>
            <strong>Postal Code:</strong>
          </Col>
          <Col span={8}>{bidderRegistration.shippingAddressPostalCode}</Col>

          <Col span={4}>
            <strong>Country:</strong>
          </Col>
          <Col span={8}>{bidderRegistration.shippingAddressCountry}</Col>

          <Col span={4}>
            <strong>Coordination Type:</strong>
          </Col>
          <Col span={8}>
            {bidderRegistration.shippingAddressCoordinationType}
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default ShippingAddress;
