import { Lot } from "../../../../generated/types";
import React, { useState } from "react";
import usePagination from "../../../../utils/usePagination";
import useFetching from "../../../../utils/useFetching";
import Table from "../../../layouts/table";
import { formatDate } from "../../../../Util";
import ConfirmationModal from "../../../ConfirmationModal";
import { post } from "../../../../utils/http";
import { useToast } from "../../../../providers/notification.provider";

type AuctionRegistrationsTabProps = {
  lot: Lot;
};

const columns = [
  {
    title: "Registration Auction Mobility ID",
    dataIndex: "auctionMobilityId",
    key: "auctionMobilityId",
  },
  { title: "Account", dataIndex: ["account", "name"], key: "accountName" },
  { title: "Paddle", dataIndex: "paddle", key: "paddle" },
  {
    title: "Account Auction Mobility Email",
    dataIndex: ["account", "auctionMobilityEmail"],
    key: "auctionMobilityEmail",
  },
  {
    title: "Account Auction Mobility ID",
    dataIndex: ["account", "auctionMobilityId"],
    key: "accountAuctionMobilityId",
  },
  { title: "At", dataIndex: "createdAt", key: "createdAt", render: formatDate },
];

const AuctionRegistrationsTab = ({ lot }: AuctionRegistrationsTabProps) => {
  const [preRegistering, setPreRegistering] = useState(false);
  const [showPreregister, setShowPreregister] = useState(false);
  const toast = useToast();

  const { page, onChange } = usePagination();
  const { data, meta, isLoading } = useFetching(
    `/lots/${lot.id}/registrations`,
    { page },
    [page],
  );

  const preRegisterAccounts = async () => {
    setPreRegistering(true);
    setShowPreregister(false);
    await post(`/lots/${lot.id}/mass_registration`);
    setPreRegistering(false);
    toast({
      type: "good",
      message: "Started pre-registering accounts. Please check back later.",
    });
  };

  return (
    <div className="box">
      <div className="columns is-vcentered">
        <div className="column">
          <h2 className="subtitle">Auction Registrations</h2>
        </div>
        <div className="column">
          <div className="buttons is-pulled-right">
            <button
              disabled={preRegistering}
              onClick={() => setShowPreregister(true)}
              className="button is-warning is-light"
            >
              Pre-Register Bidding Accounts
            </button>
          </div>
        </div>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        meta={meta}
        onChange={onChange}
        loading={isLoading}
      />
      <ConfirmationModal
        bodyText={`Are you sure you want to register accounts for this auction? Please ensure the AM id is correct! AM is ${lot.auctionMobilityId}`}
        confirmText={"Pre-Register Accounts"}
        visible={showPreregister}
        onConfirm={() => preRegisterAccounts()}
        onCancel={() => setShowPreregister(false)}
      />
    </div>
  );
};

export default AuctionRegistrationsTab;
