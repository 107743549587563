import React from "react";
import { Card } from "antd";
import MassNotificationsForm from "../Form";

const MainTab = () => {
  return (
    <>
      <Card title="Mass Notifications">
        <MassNotificationsForm />
      </Card>
    </>
  );
};

export default MainTab;
