import React from "react";
import "react-multi-email/dist/style.css";
import MainTab from "./tabs/MainTab";
import NotificationsTab from "./tabs/NotificationsTab";
import { Tabs } from "antd";
import EventsTab from "./tabs/EventsTab";

const MassNotificationsPage = () => {
  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="Main" key="1">
        <MainTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Notifications" key="2">
        <NotificationsTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Events" key="3">
        <EventsTab />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default MassNotificationsPage;
