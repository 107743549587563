import { User, Event } from "../../../../generated/types";
import Table from "../../../../components/layouts/table";
import { formatDateTime } from "../../../../Util";
import ImageGallery from "../../../../components/ImageGallery";
import useFetching from "../../../../utils/useFetching";
import usePagination from "../../../../utils/usePagination";

type SellRequestsTabProps = {
  user: User;
};

const renderLink = (value: string) => {
  if (!value) return null;

  const normalizedLink =
    value.startsWith("http://") || value.startsWith("https://")
      ? value
      : `https://${value}`;

  return (
    <a href={normalizedLink} target="_blank" rel="noopener noreferrer">
      Link
    </a>
  );
};

const renderImages = (images: { fileUrl: string }[]) => {
  if (!images || images.length === 0) return null;
  const imageUrls = images.map((image) => image.fileUrl);

  return <ImageGallery images={imageUrls} />;
};

const columns = [
  { title: "Artist Name", key: "artistName", dataIndex: "artistName" },
  { title: "Details", key: "details", dataIndex: "details" },
  {
    title: "Relevant Link",
    key: "relevantLink",
    dataIndex: "relevantLink",
    render: renderLink,
  },
  {
    title: "Images",
    key: "images",
    dataIndex: "images",
    render: renderImages,
  },
  {
    title: "Created at",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value: any) => formatDateTime(value),
  },
];

const baseParams = {
  order: "created_at",
  direction: "desc",
};

const SellRequestsTab = ({ user }: SellRequestsTabProps) => {
  const { page, onChange } = usePagination();
  const params = { ...baseParams, page };
  const { data, meta, isLoading } = useFetching<Event[]>(
    `/users/${user.id}/sell_requests`,
    params,
    [page],
  );

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      meta={meta}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default SellRequestsTab;
