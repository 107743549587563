import React from "react";
import { Button, Form, Input, Select, Flex, Modal } from "antd";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import useForm from "./hooks/useForm";

const MassNotificationsForm = () => {
  const {
    form,
    onConfirm,
    onSubmit,
    handleCancel,
    handleOk,
    type,
    emails,
    channel,
    message,
    isOpen,
    isSubmitting,
    showTitle,
    showMessage,
    showEmails,
    showTemplates,
    templates,
  } = useForm();

  return (
    <Form
      labelCol={{ flex: "110px" }}
      name="basic"
      form={form}
      onFinish={onSubmit}
    >
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select placeholder="Select one">
          <Select.Option value="ACCEPTED_USERS">
            All Accepted Users
          </Select.Option>
          <Select.Option value="USERS_WITH_BIDDING_ACCOUNT">
            All Users with Bidding Account
          </Select.Option>
          <Select.Option value="USER_WITH_EMAIL">
            To specific users
          </Select.Option>
        </Select>
      </Form.Item>
      {showEmails && (
        <Form.Item
          name="emails"
          label="User e-mail"
          rules={[{ required: true }]}
        >
          <ReactMultiEmail
            emails={emails}
            placeholder="Enter a comma separated list of e-mails"
            validateEmail={(email) => isEmail(email)}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    x
                  </span>
                </div>
              );
            }}
          />
        </Form.Item>
      )}
      <Form.Item name="channel" label="Channel">
        <Select placeholder="Select one">
          <Select.Option value="SMS">SMS</Select.Option>
          <Select.Option value="PUSH">Push Notification</Select.Option>
          <Select.Option value="EMAIL">Email</Select.Option>
        </Select>
      </Form.Item>
      {showTemplates && (
        <Form.Item
          name="templateId"
          label="Template"
          rules={[{ required: true }]}
        >
          <Select placeholder="Select one">
            {templates.map((template) => (
              <Select.Option key={template.id} value={template.id}>
                {template.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {showTitle && (
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input required />
        </Form.Item>
      )}
      {showMessage && (
        <Form.Item name="message" label="Message" rules={[{ required: true }]}>
          <Input required />
        </Form.Item>
      )}

      <Flex justify="flex-end">
        <Form.Item>
          <Button type="primary" onClick={onConfirm}>
            Submit
          </Button>
        </Form.Item>
      </Flex>

      <Modal
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isSubmitting}
      >
        You are about to send {message} to {type}. Are you sure? This will send
        message by using the {channel} channel to all the selected users.
      </Modal>
    </Form>
  );
};

export default MassNotificationsForm;
