import React from "react";
import { useState } from "react";
import * as backend from "../../Backend";
import { ReferralCodeForm } from "../ReferralCodeForm";
import ReferralProgramMassEnable from "../referral_program/mass_enable";
import { useToast } from "../../providers/notification.provider";
import usePagination from "../../utils/usePagination";
import useFetching from "../../utils/useFetching";
import { ReferralCode } from "../../generated/types";
import BooleanOutlinedCell from "../layouts/cells/BooleanOutlinedCell";
import Table from "../layouts/table";

const InviteCodesPage = () => {
  const toast = useToast();
  const { page, onChange } = usePagination();
  const {
    data: referralCodes,
    meta,
    isLoading,
    onLoadData,
  } = useFetching("/referral_codes", { with_referrer: "false", page }, [page]);
  const [showForm, setShowForm] = useState(false);
  const [massEnableReferralProgram, setMassEnableReferralProgram] =
    useState(false);

  const update = async (referralCode: any) => {
    await backend.referralCodes.update(referralCode.id, {
      referral_code: { active: !referralCode.active },
    });
    await onLoadData();
  };

  const onAutoAccept = async (referralCode: any) => {
    await backend.referralCodes.update(referralCode.id, {
      referral_code: { auto_accept: !referralCode.autoAccept },
    });
    await onLoadData();
  };

  const destroy = async (referralCode: any) => {
    await backend.referralCodes.delete(referralCode.id);
    toast({ type: "good", message: "Deleted" });
    await onLoadData();
  };

  const columns = [
    { title: "Code", dataIndex: "code", key: "code" },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: BooleanOutlinedCell,
    },
    {
      title: "Auto-Accept",
      dataIndex: "autoAccept",
      key: "autoAccept",
      render: BooleanOutlinedCell,
    },
    { title: "Users Count", dataIndex: "usersCount", key: "usersCount" },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: ReferralCode) => (
        <div className="buttons">
          <button
            className="button is-small is-danger"
            disabled={record.usersCount > 0}
            onClick={() => destroy(record)}
          >
            Delete
          </button>
          <button
            className="button is-small is-primary"
            onClick={() => update(record)}
          >
            Change Active
          </button>
          <button
            className="button is-small is-primary"
            onClick={() => onAutoAccept(record)}
          >
            Change Auto-Accept
          </button>
        </div>
      ),
    },
  ];

  const onClose = (forceReload = false) => {
    setShowForm(false);
    if (forceReload) {
      onLoadData();
    }
  };

  const onCloseMassEnable = (forceReload = false) => {
    setMassEnableReferralProgram(false);
    if (forceReload) {
      onLoadData();
    }
  };

  if (showForm) {
    return <ReferralCodeForm onClose={onClose} />;
  }

  if (massEnableReferralProgram) {
    return <ReferralProgramMassEnable onClose={onCloseMassEnable} />;
  }

  return (
    <div>
      <div className="columns is-vcentered">
        <div className="column">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <button
                className="button is-primary"
                onClick={() => setShowForm(true)}
              >
                New Invite Code
              </button>
              &nbsp;
              <button
                className="button is-light"
                onClick={() => setMassEnableReferralProgram(true)}
              >
                Mass Enable Referral Program
              </button>
            </ul>
          </nav>
        </div>
      </div>
      <Table
        dataSource={referralCodes}
        columns={columns}
        rowKey="id"
        meta={meta}
        onChange={onChange}
        loading={isLoading}
      />
    </div>
  );
};

export default InviteCodesPage;
