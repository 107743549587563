import React from "react";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import NewSection from "./components/NewSection";
import useSections from "./useSections";
import SectionItem from "./components/SectionItem";

interface SectionProps {
  lotId: string;
  toast: (toast: any) => void;
}

const Sections: React.FC<SectionProps> = ({ lotId, toast }) => {
  const {
    sections,
    showSections,
    sectionPositions,
    setSectionPositions,
    setShowSections,
    handleDragEnd,
    handleDragStart,
    handleDragCancel,
    reorderSections,
    setReorderSections,
    saveSectionOrdering,
    onEditSection,
    onDeleteSection,
    showNewSection,
    saveNewSection,
    onAddSection,
    setShowNewSection,
    isDragging,
    newSection,
    setNewSection,
  } = useSections(lotId, toast);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 10 },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  return (
    <>
      <NewSection
        {...{
          showNewSection,
          setShowNewSection,
          saveNewSection,
          newSection,
          setNewSection,
        }}
      />

      <h2>
        Text and audio sections{" "}
        <span
          className="as_link"
          onClick={() => setShowSections(!showSections)}
        >
          {showSections ? "Hide" : "Show"}
        </span>
      </h2>

      {showSections && (
        <div className="box">
          {sections && (
            <>
              <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
              >
                <SortableContext
                  items={sections.map((section) => section.id)}
                  strategy={rectSortingStrategy}
                >
                  {sections.map((section) => (
                    <SectionItem
                      key={section.id}
                      section={section}
                      reorderSections={reorderSections}
                      sectionPositions={sectionPositions}
                      setSectionPositions={setSectionPositions}
                      onEditSection={onEditSection}
                      onDeleteSection={onDeleteSection}
                      isMoving={isDragging}
                    />
                  ))}
                </SortableContext>
              </DndContext>

              <div className="buttons is-right">
                {reorderSections ? (
                  <>
                    <button
                      className="button is-danger is-light"
                      onClick={() => setReorderSections(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="button is-primary"
                      onClick={() => saveSectionOrdering()}
                    >
                      Save Ordering
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="button"
                      onClick={() => setReorderSections(true)}
                    >
                      Reorder
                    </button>
                    <button className="button" onClick={onAddSection}>
                      Add
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Sections;
