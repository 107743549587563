"use client";

import useHomesplashes from "./useHomesplashes";
import Table from "../../layouts/table";
import React from "react";
import { Button } from "antd";
import ActionsLayout from "../../layouts/ActionsLayout";

const HomesplashesPage = () => {
  const {
    homesplashes,
    meta,
    isLoading,
    onChange,
    columns,
    onClickNewHomeSplash,
  } = useHomesplashes();

  return (
    <>
      <ActionsLayout>
        <Button onClick={onClickNewHomeSplash}>New homesplash</Button>
      </ActionsLayout>

      <Table
        dataSource={homesplashes}
        columns={columns}
        meta={meta}
        loading={isLoading}
        onChange={onChange}
        rowKey="id"
      />
    </>
  );
};

export default HomesplashesPage;
