"use client";

import React from "react";
import LotsPage from "../../components/pages/LotsPage";

const LotsNextPage = () => {
  return <LotsPage />;
};

export default LotsNextPage;
