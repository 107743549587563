import { User } from "../../../generated/types";
import ActionsLayout from "../../layouts/ActionsLayout";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React from "react";
import ConfirmationModal from "../../ConfirmationModal";
import useActions from "./useActions";

type ActionsProps = {
  user: User;
  onReloadUser: () => void;
};

const Actions = ({ user, onReloadUser }: ActionsProps) => {
  const {
    items,
    labels,
    showAcceptConfirmation,
    onAccept,
    onClickAccept,
    onCancelAccept,
    labelsDisabled,
    acceptBodyText,
  } = useActions({ user, onReloadUser });

  return (
    <>
      <ActionsLayout>
        <Dropdown.Button icon={<DownOutlined />} menu={{ items }}>
          Actions
        </Dropdown.Button>
        <Dropdown.Button
          icon={<DownOutlined />}
          menu={{ items: labels }}
          disabled={labelsDisabled}
        >
          Labels
        </Dropdown.Button>
        {user.profile.acceptedAt === null && (
          <Button color="danger" variant="solid" onClick={onClickAccept}>
            Accept
          </Button>
        )}
      </ActionsLayout>
      <ConfirmationModal
        bodyText={acceptBodyText}
        confirmText="Accept"
        visible={showAcceptConfirmation}
        onConfirm={onAccept}
        onCancel={onCancelAccept}
      />
    </>
  );
};

export default Actions;
