import { useToast } from "../../../providers/notification.provider";
import { useState } from "react";
import { User } from "../../../generated/types";
import { post, put } from "../../../utils/http";

type UseActionsProps = {
  user: User;
  onReloadUser: () => void;
};

const useActions = ({ user, onReloadUser }: UseActionsProps) => {
  const toast = useToast();
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const onClickAccept = () => setShowAcceptConfirmation(true);
  const onCancelAccept = () => setShowAcceptConfirmation(false);

  const onAccept = async () => {
    await post(`/users/${user.id}/acceptance`);
    onReloadUser();
    toast({
      type: "good",
      message: "User accepted",
    });
  };

  const toggleAdmin = async () => {
    await put(`/users/${user.id}`, { user: { admin: !user.admin } });
    onReloadUser();
    toast({
      type: "good",
      message: "Admin state changed",
    });
  };

  const unlockUser = async () => {
    await put(`/users/${user.id}`, {
      user: { locked_at: null, login_attempts: 0 },
    });
    onReloadUser();
    toast({
      type: "good",
      message: "User unlocked",
    });
  };

  const toggleDisable = async () => {
    await put(`/users/${user.id}`, { user: { disabled: !user.disabled } });
    onReloadUser();
    toast({
      type: "good",
      message: "User disabled",
    });
  };

  const updateUserOnApp = async () => {
    await post(`/users/${user.id}/send_event`);
    onReloadUser();
    toast({
      type: "good",
      message: "User updated on app",
    });
  };

  const onHandleLabel = async (label: string) => {
    await put(`/users/${user.id}`, { user: { status: label } });
    onReloadUser();
    toast({
      type: "good",
      message: "User labeled",
    });
  };

  const items = [
    {
      label: "Toggle Admin",
      key: "toggleAdmin",
      onClick: toggleAdmin,
    },
    {
      label: "Unlock",
      key: "unlock",
      onClick: unlockUser,
    },
    {
      label: user.disabled ? "Enable" : "Disable",
      key: "toggleDisable",
      onClick: toggleDisable,
    },
    {
      label: "Update user on app",
      key: "updateUserOnApp",
      onClick: updateUserOnApp,
    },
  ];

  const labels = [
    {
      label: "On Hold",
      key: "onHold",
      onClick: () => onHandleLabel("on_hold"),
    },
    {
      label: "Pending",
      key: "pending",
      onClick: () => onHandleLabel("pending"),
    },
    {
      label: "Rejected",
      key: "rejected",
      onClick: () => onHandleLabel("rejected"),
    },
  ];

  const labelsDisabled = user.profile.acceptedAt !== null;
  const acceptBodyText = `Would you like to accept ${user.firstName} ${user.lastName}? There's no undo.`;

  return {
    items,
    labels,
    showAcceptConfirmation,
    onAccept,
    onClickAccept,
    onCancelAccept,
    labelsDisabled,
    acceptBodyText,
  };
};

export default useActions;
