import { Account, User } from "../../../../generated/types";
import { formatDateTime } from "../../../../Util";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useToast } from "../../../../providers/notification.provider";
import BooleanOutlinedCell from "../../../layouts/cells/BooleanOutlinedCell";
import Table from "../../../layouts/table";
import { Button, Card, Space } from "antd";
import { post, put } from "../../../../utils/http";

type AuctionMobilityAccountsTabProps = {
  user: User;
  onReloadUser: () => void;
};

const AuctionMobilityAccountsTab = ({
  user,
  onReloadUser,
}: AuctionMobilityAccountsTabProps) => {
  const toast = useToast();
  const [showNewAccount, setShowNewAccount] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const makePrimary = async (account: Account) => {
    await post(`/users/${user.id}/accounts/${account.id}/promotions`);
    onReloadUser();
    toast({ type: "good", message: "New primary Account." });
  };

  const toggleBidding = async (account: Account) => {
    await put(`/accounts/${account.id}/can_bid`, { canBid: !account.canBid });
    onReloadUser();
  };

  const createAccount = async (data: any) => {
    await post(`/users/${user.id}/accounts`, { ...data });
    reset();
    setShowNewAccount(false);
    onReloadUser();
    toast({ type: "good", message: "Created account." });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "120px",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any) => formatDateTime(createdAt),
      width: "150px",
    },
    {
      title: "Auction Mobility ID",
      dataIndex: "auctionMobilityId",
      key: "auctionMobilityId",
      width: "120px",
    },
    {
      title: "Auction Mobility Email",
      dataIndex: "auctionMobilityEmail",
      key: "auctionMobilityEmail",
      width: "200px",
    },
    {
      title: "Primary",
      dataIndex: "primary",
      key: "primary",
      render: BooleanOutlinedCell,
    },
    {
      title: "Can Bid?",
      dataIndex: "canBid",
      key: "canBid",
      render: BooleanOutlinedCell,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, account: Account) => (
        <Space direction="horizontal" size="middle">
          {!account.primary && (
            <Button
              color="primary"
              variant="solid"
              onClick={() => makePrimary(account)}
            >
              Promote
            </Button>
          )}
          <Button color="primary" onClick={() => toggleBidding(account)}>
            {account.canBid ? "Disable Bidding" : "Enable Bidding"}
          </Button>
        </Space>
      ),
    },
  ];

  const title = `Auction Mobility Accounts (${user.fullName})`;

  return (
    <>
      <Card title={title}>
        <Table dataSource={user.accounts} columns={columns} rowKey={"id"} />

        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "end", marginTop: "10px" }}
        >
          <Button
            color="danger"
            variant="solid"
            onClick={() => setShowNewAccount(true)}
          >
            New Account
          </Button>
        </Space>
      </Card>
      <div className={`modal ${showNewAccount ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setShowNewAccount(false)}
        />
        <div className="modal-content">
          <div className="box">
            <form onSubmit={handleSubmit(createAccount)}>
              <div className="field">
                <label htmlFor="name" className="label">
                  Name
                </label>
                <div className="control">
                  <input
                    className="input"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <p className="help is-danger">Name required</p>
                  )}
                </div>
              </div>
              <div className="field">
                <label htmlFor="type" className="label">
                  Type
                </label>
                <div className="control">
                  <select {...register("type")}>
                    <option value="INDIVIDUAL">Individual</option>
                    <option value="COMPANY">Company</option>
                  </select>
                  {errors.type && (
                    <p className="help is-danger">Type required</p>
                  )}
                </div>
              </div>

              <button className="button is-success" type="submit">
                Create
              </button>
            </form>
          </div>
        </div>
        <button
          className="modal-close is-large"
          onClick={() => setShowNewAccount(false)}
        />
      </div>
    </>
  );
};

export default AuctionMobilityAccountsTab;
