import Table from "../../../layouts/table";
import React, { useState } from "react";
import useFetching from "../../../../utils/useFetching";

const columnsEvents = [
  { title: "At", key: "createdAt", dataIndex: "createdAt" },
  { title: "Type", key: "type", dataIndex: "type" },
  {
    title: "Payload",
    key: "payload",
    dataIndex: "payload",
    render: (payload: any) => JSON.stringify(payload),
  },
];

const EventsTab = () => {
  const [pageEvents, setPageEvents] = useState(1);
  const {
    data: events,
    meta: eventsMeta,
    isLoading: isEventsLoading,
  } = useFetching("/mass_notifications/events", { page: pageEvents }, [
    pageEvents,
  ]);

  const onChangeEventsPage = (page: number) => setPageEvents(page);

  return (
    <Table
      dataSource={events}
      columns={columnsEvents}
      rowKey="id"
      meta={eventsMeta}
      onChange={onChangeEventsPage}
      loading={isEventsLoading}
    />
  );
};

export default EventsTab;
