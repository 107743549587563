"use client";

import React from "react";
import UserPage from "../../../components/pages/UserPage";
import { useParams } from "../../../utils/navigationUtils";

const UserDetailsNextPage = () => {
  const { id } = useParams();

  return <UserPage id={id!} />;
};

export default UserDetailsNextPage;
