import { ReferralCode, User } from "../../../../generated/types";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Flex, Input, Space } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import Table from "../../../layouts/table";
import { useToast } from "../../../../providers/notification.provider";
import { get, post, put } from "../../../../utils/http";

type ReferralProgramTabProps = {
  user: User;
};

const columns = [
  { title: "Full Name", key: "fullName", dataIndex: "fullName" },
  { title: "Email", key: "email", dataIndex: "email" },
  { title: "Date redeemed", key: "createdAt", dataIndex: "createdAt" },
];

const ReferralProgramTab = ({ user }: ReferralProgramTabProps) => {
  const toast = useToast();
  const [referralProgram, setReferralProgram] = useState<ReferralCode | null>();
  const [editing, setEditing] = useState(false);
  const [code, setCode] = useState("");

  const loadReferralProgram = useCallback(async () => {
    try {
      const response = await get<ReferralCode>(`/users/${user?.id}/referrer`);
      setReferralProgram(response.data);
      setCode(response?.data?.code || "");
    } catch (e) {}
  }, [user?.id]);

  const onUpdate = async (body: any) => put(`/users/${user.id}/referrer`, body);

  const onEnable = useCallback(async () => {
    await post(`/users/${user.id}/referrer`);
    loadReferralProgram();
    toast({ type: "good", message: "Referral program enabled" });
  }, [user?.id, referralProgram?.id]) // eslint-disable-line

  const onDisable = useCallback(async () => {
    await onUpdate({ active: false });
    loadReferralProgram();
    toast({ type: "good", message: "Referral program disabled" });
  }, [user?.id]) // eslint-disable-line

  const onAddRemaining = useCallback(async () => {
    await put(`/users/${user.id}/referrer`, {
      remaining: (referralProgram?.remaining || 0) + 1,
    });
    loadReferralProgram();
    toast({ type: "good", message: "Referral remaining added" });
  }, [user?.id, referralProgram?.remaining]) // eslint-disable-line

  const onRemoveRemaining = useCallback(async () => {
    await put(`/users/${user.id}/referrer`, {
      remaining: (referralProgram?.remaining || 0) - 1,
    });
    loadReferralProgram();
    toast({ type: "good", message: "Referral remaining removed" });
  }, [user?.id, referralProgram?.remaining]) // eslint-disable-line

  const onChangeAutoAccept = () =>
    onUpdate({ auto_accept: !referralProgram?.autoAccept }).then((response) => {
      loadReferralProgram();
      toast({ type: "good", message: "Auto-accept changed" });
    });

  const onEdit = () => setEditing(true);
  const onCancelEdit = () => setEditing(false);
  const onChangeCode = (e: any) => setCode(e.target.value);
  const onSaveCode = async () => {
    await onUpdate({ code });
    loadReferralProgram();
    setEditing(false);
    toast({ type: "good", message: "Code updated" });
  };

  const isEnabled = referralProgram?.active;
  const title = `Referral Program - ${user?.fullName}`;

  useEffect(() => {
    loadReferralProgram();
  }, [user?.id]); // eslint-disable-line

  return (
    <Space direction="vertical" size={16} style={{ width: "100%" }}>
      <Card title={title}>
        <div className="columns">
          <div className="column">
            <Space
              direction="horizontal"
              style={{
                width: "100%",
                justifyContent: "end",
                marginBottom: "10px",
              }}
            >
              <Button
                color="primary"
                variant="solid"
                disabled={isEnabled}
                onClick={onEnable}
              >
                Enable
              </Button>
              <Button
                color="danger"
                variant="solid"
                disabled={!isEnabled}
                onClick={onDisable}
              >
                Disable
              </Button>
              <Button
                color="primary"
                variant="outlined"
                disabled={!isEnabled}
                onClick={onChangeAutoAccept}
              >
                Change Auto-Accept
              </Button>
            </Space>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <strong>Code:</strong>
          </div>
          <div className="column">
            {editing ? (
              <Flex gap="4px">
                <Input
                  type="text"
                  value={code}
                  onChange={onChangeCode}
                  placeholder="Code"
                />
                <CheckOutlined onClick={onSaveCode} />
                &nbsp;
                <CloseOutlined onClick={onCancelEdit} />
              </Flex>
            ) : (
              <div>
                {referralProgram?.code || "-"}&nbsp;
                {isEnabled && (
                  <EditOutlined className="cursor-pointer" onClick={onEdit} />
                )}
              </div>
            )}
          </div>
          <div className="column"></div>
          <div className="column"></div>
        </div>

        <div className="columns">
          <div className="column">
            <strong>Auto-Accept:</strong>
          </div>
          <div className="column">{referralProgram?.autoAccept ? "✓" : ""}</div>
          <div className="column"></div>
          <div className="column"></div>
        </div>

        <div className="columns">
          <div className="column">
            <strong>Referral Remaining:</strong>
          </div>
          <div className="column">{referralProgram?.remaining}</div>
          <div className="column">
            <Space direction="horizontal" size={8}>
              <Button
                color="primary"
                variant="solid"
                disabled={!isEnabled}
                onClick={onAddRemaining}
              >
                Add
              </Button>
              <Button
                color="danger"
                variant="solid"
                disabled={!isEnabled || referralProgram?.remaining === 0}
                onClick={onRemoveRemaining}
              >
                Remove
              </Button>
            </Space>
          </div>
          <div className="column"></div>
        </div>

        <div className="columns">
          <div className="column">
            <strong>Link:</strong>
          </div>
          <div className="column">{referralProgram?.link}</div>
          <div className="column"></div>
          <div className="column"></div>
        </div>

        <div className="columns">
          <div className="column">
            <strong>Referrals Redeemed:</strong>
          </div>
        </div>
      </Card>
      <Table
        dataSource={referralProgram?.redemptions}
        columns={columns}
        rowKey="id"
      />
    </Space>
  );
};

export default ReferralProgramTab;
