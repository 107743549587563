import { Lot } from "../../../../generated/types";
import ConfirmationModal from "../../../ConfirmationModal";
import React from "react";
import { put } from "../../../../utils/http";
import { useToast } from "../../../../providers/notification.provider";

type ActivateAuctionLiveModalProps = {
  lot: Lot;
  visible: boolean;
  onClose: () => void;
  onReloadLot: () => void;
};

const ActivateAuctionLiveModal = ({
  lot,
  visible,
  onClose,
  onReloadLot,
}: ActivateAuctionLiveModalProps) => {
  const toast = useToast();

  const onConfirm = async () => {
    await put(`/lots/${lot.id}/auction_live`, {
      auction_live: !lot.auctionLive,
    });
    onReloadLot();
    onClose();
    toast({
      type: "good",
      message: `Lot ${lot.auctionLive ? "deactivated" : "activated"}`,
    });
  };

  return (
    <ConfirmationModal
      bodyText={`Are you sure you want to ${lot.auctionLive ? "DEACTIVATE" : "ACTIVATE"} auction?`}
      confirmText={lot.auctionLive ? "Deactivate" : "Activate"}
      visible={visible}
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  );
};

export default ActivateAuctionLiveModal;
