import React from "react";
import FormModal from "../../../FormModal";

interface FormModalProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: (vimeoId: string, width: number, height: number) => void;
}

const VimeoFormModal: React.FC<FormModalProps> = ({
  visible,
  onCancel,
  onConfirm,
}) => {
  return (
    <FormModal
      form={[
        { label: "Vimeo ID", inputType: "text", required: true },
        { label: "Width", inputType: "number", required: false },
        { label: "Height", inputType: "number", required: false },
      ]}
      visible={visible}
      confirmText="Save"
      onCancel={onCancel}
      onConfirm={(vimeoId, width, height) =>
        onConfirm(vimeoId, +width, +height)
      }
    />
  );
};

export default VimeoFormModal;
