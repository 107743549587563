import { Lot } from "../../../../generated/types";
import FormModal from "../../../FormModal";
import * as backend from "../../../../Backend";
import { useToast } from "../../../../providers/notification.provider";

type MoveToPastLotModalProps = {
  lot: Lot;
  visible: boolean;
  onClose: () => void;
  onReloadLot: () => void;
};

const MoveToPastLotModal = ({
  lot,
  visible,
  onClose,
  onReloadLot,
}: MoveToPastLotModalProps) => {
  const toast = useToast();

  const onConfirm = async (soldPrice: string, soldOn: string) => {
    try {
      await backend.pastLot.create({ lotId: lot!.id, soldPrice, soldOn });
      await onReloadLot();
      onClose();
      toast({
        type: "good",
        message: "This lot has been added to the list of past lots",
      });
    } catch (e) {
      onClose();
      toast({
        type: "bad",
        message: "Could not add this lot to the list of past lots",
      });
    }
  };

  return (
    <FormModal
      form={[
        { label: "Enter sold price", required: true },
        {
          label: "Enter date at when the lot was sold",
          inputType: "date",
          required: true,
        },
      ]}
      visible={visible}
      confirmText={"Move to past lots"}
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  );
};

export default MoveToPastLotModal;
