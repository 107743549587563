"use client";

import React from "react";
import ParkingLotsPage from "../../components/pages/ParkingLotsPage";

const ParkingLotsNextPage = () => {
  return <ParkingLotsPage />;
};

export default ParkingLotsNextPage;
