import { useCallback, useEffect, useState } from "react";
import { get } from "../../../utils/http";
import { User } from "../../../generated/types";
import { Tabs } from "antd";
import UserDetailsTab from "./tabs/UserDetailsTab";
import Loading from "../../layouts/loading";
import EventsTab from "./tabs/EventsTab";
import SellRequestsTab from "./tabs/SellRequestsTab";
import QuestionnaireTab from "./tabs/QuestionnaireTab";
import BidderRegistrationTab from "./tabs/BidderRegistrationTab";
import ReferralProgramTab from "./tabs/ReferralProgramTab";
import AuctionMobilityAccountsTab from "./tabs/AuctionMobilityAccountsTab";
import Actions from "./Actions";
import NotificationsTab from "./tabs/NotificationsTab";

type UserPageProps = {
  id: string;
};

const UserPage = ({ id }: UserPageProps) => {
  const [user, setUser] = useState<User | null>(null);
  const onReloadUser = useCallback(async () => {
    if (!id) {
      return;
    }

    const response = await get<User>(`/users/${id}`);
    setUser(response.data);
  }, [id]);

  useEffect(() => {
    onReloadUser();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) {
    return <Loading />;
  }

  return (
    <>
      <Actions user={user} onReloadUser={onReloadUser} />
      <Tabs defaultActiveKey="details">
        <Tabs.TabPane tab="Details" key="details">
          <UserDetailsTab user={user} onReloadUser={onReloadUser} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Bidder Registration" key="bidderRegistration">
          <BidderRegistrationTab user={user} onReloadUser={onReloadUser} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Referral Program" key="referralProgram">
          <ReferralProgramTab user={user} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Auction Mobility Accounts"
          key="auctionMobilityAccounts"
        >
          <AuctionMobilityAccountsTab user={user} onReloadUser={onReloadUser} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Questionnaire" key="questionnaire">
          <QuestionnaireTab user={user} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Sell Requests" key="sellRequests">
          <SellRequestsTab user={user} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Events" key="events">
          <EventsTab user={user} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Notifications" key="notifications">
          <NotificationsTab user={user} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default UserPage;
