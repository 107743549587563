import { Lot, LotsRsvp } from "../../../../generated/types";
import React from "react";
import usePagination from "../../../../utils/usePagination";
import useFetching from "../../../../utils/useFetching";
import Table from "../../../layouts/table";
import { Link } from "../../../../utils/navigationUtils";

type PaddlesTabProps = {
  lot: Lot;
};

const columns = [
  { title: "Email", dataIndex: ["user", "email"], key: "email" },
  { title: "First Name", dataIndex: ["user", "firstName"], key: "firstName" },
  { title: "Last Name", dataIndex: ["user", "lastName"], key: "lastName" },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    render: (_: any, rsvp: LotsRsvp) => (
      <Link
        to={`/users/${rsvp.user?.id}`}
        className="button is-small is-primary"
      >
        View
      </Link>
    ),
  },
];

const PaddlesTab = ({ lot }: PaddlesTabProps) => {
  const { page, onChange } = usePagination();
  const { data, meta, isLoading } = useFetching(
    `/lots/${lot.id}/rsvps`,
    { page },
    [page],
  );

  return (
    <div className="box">
      <div className="columns is-vcentered">
        <div className="column">
          <h2 className="subtitle">Users requested a paddle</h2>
        </div>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        meta={meta}
        onChange={onChange}
        loading={isLoading}
      />
    </div>
  );
};

export default PaddlesTab;
