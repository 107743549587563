import { Button, Card, Space } from "antd";
import { formatDateTime } from "../../../../../Util";
import React, { useCallback, useEffect, useState } from "react";
import { User, IdentityVerification } from "../../../../../generated/types";
import BooleanOutlinedCell from "../../../../layouts/cells/BooleanOutlinedCell";
import ActionsLayout from "../../../../layouts/ActionsLayout";
import Table from "../../../../layouts/table";
import { get, post } from "../../../../../utils/http";

type UserStepsProps = {
  user: User;
  canEditBidderRegistration: boolean;
};

const UserSteps = ({ user, canEditBidderRegistration }: UserStepsProps) => {
  const [identityVerifications, setIdentityVerifications] = useState<
    IdentityVerification[]
  >([]);

  const onLoadIdentityVerifications = useCallback(async () => {
    const response = await get<IdentityVerification[]>(
      `/users/${user.id}/bidder_registration/identity_verifications`,
    );

    setIdentityVerifications(response.data);
  }, [user.id]);

  const createIdentityVerificationOverride = async () => {
    await post(`/users/${user.id}/bidder_registration/identity_verifications`);
    onLoadIdentityVerifications();
  };

  const onApproveIdentifyVerification = async (
    identityVerificationId: string,
  ) => {
    await post(
      `/users/${user.id}/bidder_registration/identity_verifications/${identityVerificationId}/approve`,
    );
    onLoadIdentityVerifications();
  };

  const onRejectIdentifyVerification = async (
    identityVerificationId: string,
  ) => {
    await post(
      `/users/${user.id}/bidder_registration/identity_verifications/${identityVerificationId}/reject`,
    );
    onLoadIdentityVerifications();
  };

  const columns = [
    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (createdAt: any) => formatDateTime(createdAt),
    },
    { title: "State", key: "state", dataIndex: "state" },
    { title: "Type", key: "type", dataIndex: "type" },
    {
      title: "Automated?",
      key: "automated",
      dataIndex: "automated",
      render: BooleanOutlinedCell,
    },
    {
      title: "Document State",
      key: "documentState",
      dataIndex: "documentState",
      render: (_: any, identityVerification: IdentityVerification) => (
        <Button
          color="primary"
          variant="solid"
          onClick={() => onApproveIdentifyVerification(identityVerification.id)}
        >
          Approve
        </Button>
      ),
    },
    {
      title: "Identity State",
      key: "identityState",
      dataIndex: "identityState",
      render: (_: any, identityVerification: IdentityVerification) => (
        <Button
          color="danger"
          variant="solid"
          onClick={() => onRejectIdentifyVerification(identityVerification.id)}
        >
          Reject
        </Button>
      ),
    },
  ];

  useEffect(() => {
    onLoadIdentityVerifications();
  }, [user.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card title="User Steps">
      <Space direction="vertical" size={16} style={{ width: "100%" }}>
        <ActionsLayout>
          {canEditBidderRegistration && (
            <Button
              color="primary"
              variant="solid"
              onClick={createIdentityVerificationOverride}
            >
              Manually Approve
            </Button>
          )}
        </ActionsLayout>

        <Table
          dataSource={identityVerifications}
          columns={columns}
          rowKey={"id"}
        />
      </Space>
    </Card>
  );
};

export default UserSteps;
