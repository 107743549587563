"use client";

import useHomesplashesNew from "./useHomesplashesNew";
import React from "react";
import { Button, Card, Flex, Form, Input, Upload } from "antd";

const beforeUpload = () => false;

const HomesplashesNewPage = () => {
  const { form, onCancel, onSubmit } = useHomesplashesNew();

  return (
    <Card title="Create New Homesplash">
      <Form
        labelCol={{ flex: "110px" }}
        name="basic"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item name="ctaColor" label="CTA Color">
          <Input type="color" />
        </Form.Item>
        <Form.Item name="textColor" label="Text Color">
          <Input type="color" />
        </Form.Item>
        <Form.Item name="imageFile" label="Image" rules={[{ required: true }]}>
          <Upload maxCount={1} beforeUpload={beforeUpload}>
            <Button>Click to Upload</Button>
          </Upload>
        </Form.Item>

        <Flex justify="flex-end">
          <Form.Item>
            <Button onClick={onCancel} style={{ marginRight: "8px" }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Card>
  );
};

export default HomesplashesNewPage;
