import usePagination from "../../../utils/usePagination";
import useFetching from "../../../utils/useFetching";
import { Homesplash } from "../../../generated/types";
import BooleanOutlinedCell from "../../layouts/cells/BooleanOutlinedCell";
import ActionCell from "./ActionCell";
import { useNavigate } from "../../../utils/navigationUtils";
import React from "react";

const getImageUrl = (homesplash: Homesplash) =>
  homesplash.image?.variants?.thumb?.url || homesplash.imageUrl;

const renderColor = (value: string) => {
  const color = value || "#000000";
  return <span style={{ color }}>{color}</span>;
};

const useHomesplashes = () => {
  const { page, onChange } = usePagination();
  const navigate = useNavigate();
  const {
    data: homesplashes,
    meta,
    isLoading,
    onLoadData,
  } = useFetching<Homesplash[]>("/homesplashes", { page }, [page]);

  const onClickNewHomeSplash = () => {
    navigate("/homesplashes/new");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image URL",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_: any, homesplash: Homesplash) =>
        getImageUrl(homesplash) ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={getImageUrl(homesplash)}
            alt="thumbnail"
            style={{ width: "70px" }}
          />
        ) : (
          "-"
        ),
    },
    {
      title: "CTA Color",
      dataIndex: "ctaColor",
      key: "ctaColor",
      render: renderColor,
    },
    {
      title: "Text Color",
      dataIndex: "textColor",
      key: "textColor",
      render: renderColor,
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: BooleanOutlinedCell,
    },
    { title: "Actions", key: "actions", render: ActionCell(onLoadData) },
  ];

  return {
    homesplashes,
    meta,
    isLoading,
    onChange,
    columns,
    onClickNewHomeSplash,
  };
};

export default useHomesplashes;
