"use client";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import React from "react";
import { ReferralCode } from "../../generated/types";
import ActionCell from "./ActionCell";
import Table from "../../components/layouts/table";
import { Link } from "../../utils/navigationUtils";
import useFetching from "../../utils/useFetching";
import usePagination from "../../utils/usePagination";

const params = {
  with_referrer: "true",
};

const UserCell = (_: any, record: ReferralCode) => (
  <Link to={`/users/${record.referrer.id}`}>{record.referrer.fullName}</Link>
);

const ReferralCodesNextPage = () => {
  const { page, onChange } = usePagination();
  const {
    data: referralCodes,
    meta,
    onLoadData,
    isLoading,
  } = useFetching<ReferralCode[]>("/referral_codes", { ...params, page }, [
    page,
  ]);

  const columns = [
    { title: "Code", dataIndex: "code", key: "code" },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active: boolean) =>
        active ? <CheckOutlined /> : <CloseOutlined />,
    },
    {
      title: "Auto-Accept",
      dataIndex: "autoAccept",
      key: "autoAccept",
      render: (active: boolean) =>
        active ? <CheckOutlined /> : <CloseOutlined />,
    },
    { title: "Users Count", dataIndex: "usersCount", key: "usersCount" },
    { title: "Referrer", key: "referrer", render: UserCell },
    { title: "Actions", key: "actions", render: ActionCell(onLoadData) },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={referralCodes}
      meta={meta}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default ReferralCodesNextPage;
