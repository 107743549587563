import React from "react";
import Table from "../layouts/table";
import useFetching from "../../utils/useFetching";
import { useState } from "react";

const columns = [
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "created_at",
  },
  { title: "Type", dataIndex: "type", key: "type" },
  {
    title: "Payload",
    dataIndex: "payload",
    key: "payload",
    render: (payload: any) => JSON.stringify(payload),
  },
  { title: "ID", dataIndex: "id", key: "id" },
];

const baseParams = {
  order: "created_at",
  direction: "desc",
};

const EventsPage = () => {
  const [page, setPage] = useState(1);
  const { data, meta, isLoading } = useFetching(
    "/events",
    { ...baseParams, page },
    [page],
  );

  const onChange = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <Table
        onChange={onChange}
        dataSource={data}
        columns={columns}
        meta={meta}
        rowKey="id"
        loading={isLoading}
      />
    </>
  );
};

export default EventsPage;
