"use client";

import React, { useEffect, useState } from "react";
import AuctionRoom from "../../components/AuctionRoom";
import * as backend from "../../Backend";
import { Lot } from "../../generated/types";
import useFetching from "../../utils/useFetching";

const AuctionRoomsNextPage = () => {
  const [activeLot, setActiveLot] = useState<Lot | null>(null);
  const { data: upcomingLots } = useFetching<Lot[]>("/lots/upcoming", {}, []);

  useEffect(() => {
    if (upcomingLots && upcomingLots.length > 0) {
      setActiveLot(upcomingLots[0]);
    }
  }, [upcomingLots]);

  if (!activeLot) {
    return <div>Loading...</div>;
  }

  return <AuctionRoom activeLot={activeLot!} />;
};

export default AuctionRoomsNextPage;
