"use client";

import { useEffect, useState } from "react";
import { useToast } from "./notification.provider";

const InternetConnectionProvider = ({ children }: any) => {
  const [online, setOnline] = useState({
    value: true,
    initialLoad: true,
  });
  const toast = useToast();

  useEffect(() => {
    const handleOnline = () => setOnline({ value: true, initialLoad: false });
    const handleOffline = () => setOnline({ value: false, initialLoad: false });

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!online.initialLoad) {
      toast({
        type: online.value ? "good" : "bad",
        message: online.value ? "You are online" : "You are offline",
      });
    }
  }, [online]); // eslint-disable-line react-hooks/exhaustive-deps

  return children;
};

export default InternetConnectionProvider;
