"use server";

import axios from "axios";
import decamelizeKeys from "decamelize-keys";
import { MetaResult } from "../generated/types";
import { each, isArray, isObject, mapKeys, mapValues, snakeCase } from "lodash";

const Deserializer = require("jsonapi-serializer").Deserializer;

type HttpOptions = {
  method?: "get" | "post" | "put" | "delete";
  headers?: {};
  skipDeserialization?: boolean;
  isMultipart?: boolean;
};

const API_URL = process.env.REACT_APP_RAILS_API_ROOT || "http://localhost:5000";
const API_ID = process.env.REACT_APP_RAILS_API_ID;
const API_KEY = process.env.REACT_APP_RAILS_API_KEY;

const UNAUTHENTICATED = 401;
const UNAUTHORIZED = 403;

const deepFormData = (obj: any, form: any, namespace = null) => {
  const formData = form || new FormData();
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;
    const propName = namespace ? `${namespace}[${key}]` : key;
    if (obj[key] instanceof File) {
      formData.append(propName, obj[key]);
    } else if (isObject(obj[key]) && !isArray(obj[key])) {
      // @ts-ignore
      deepFormData(obj[key], formData, propName);
    } else if (isArray(obj[key])) {
      each(obj[key], (item: any, index: any) => {
        if (isObject(item)) {
          // @ts-ignore
          deepFormData(item, formData, `${propName}[${index}]`);
        } else {
          formData.append(`${propName}[${index}]`, item);
        }
      });
    } else {
      formData.append(propName, obj[key]);
    }
  }
  return formData;
};

const toSnakeCaseKeys = (obj: any): any => {
  if (obj instanceof File) {
    return obj;
  } else if (isArray(obj)) {
    return obj.map((val: any) => toSnakeCaseKeys(val));
  } else if (isObject(obj)) {
    return mapValues(
      mapKeys(obj, (v: any, k: any) => (k === "_destroy" ? k : snakeCase(k))),
      (v: any) => toSnakeCaseKeys(v),
    );
  }
  return obj;
};

const http = <T>(
  url: string,
  body?: {},
  options?: HttpOptions,
): Promise<{ data: T; meta: MetaResult }> => {
  const method = options?.method || "get";
  const skipDeserialization = options?.skipDeserialization || false;
  const token = localStorage.getItem("mfaToken");
  const contentType = options?.isMultipart
    ? "multipart/form-data"
    : "application/json";

  const headers = {
    "Content-Type": contentType,
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    "X-API-ID": API_ID,
    "X-API-KEY": API_KEY,
    ...options?.headers,
  };

  let data;

  if (options?.isMultipart) {
    data = deepFormData(toSnakeCaseKeys(body), null);
  } else {
    data = decamelizeKeys(body || {}, { deep: true });
  }

  return Promise.resolve()
    .then(() => {
      return axios({
        baseURL: API_URL,
        url,
        method,
        headers,
        ...(method === "get" ? { params: data } : { data }),
      });
    })
    .then(async (response) => {
      if (response.status === 204) {
        return {
          data: null as T,
          meta: null,
        };
      }

      const data = await new Deserializer({
        keyForAttribute: "camelCase",
      }).deserialize(response.data);

      return {
        data: data as T,
        meta: response.data?.meta,
      };
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === UNAUTHENTICATED ||
          error.response.status === UNAUTHORIZED)
      ) {
        // return redirect("/login");
      }

      throw error;
    });
};

export const get = <T>(url: string, params?: {}, options?: HttpOptions) => {
  return http<T>(url, params, { ...options, method: "get" });
};

export const post = <T>(url: string, body?: {}, options?: HttpOptions) => {
  return http<T>(url, body, { ...options, method: "post" });
};

export const put = <T>(url: string, body?: {}, options?: HttpOptions) => {
  return http<T>(url, body, { ...options, method: "put" });
};

export const del = <T>(url: string, options?: HttpOptions) => {
  return http<T>(url, {}, { ...options, method: "delete" });
};
