import { Form } from "antd";
import { useEffect, useState } from "react";
import { get, post } from "../../../../utils/http";
import { useToast } from "../../../../providers/notification.provider";

const useForm = () => {
  const [form] = Form.useForm<{
    type: string;
    emails: string[];
    channel: string;
    title: string;
    message: string;
  }>();
  const toast = useToast();

  const type = Form.useWatch("type", form);
  const emails = Form.useWatch("emails", form);
  const channel = Form.useWatch("channel", form);
  const message = Form.useWatch("message", form);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const showTitle = channel === "PUSH";
  const showMessage = ["SMS", "PUSH"].includes(channel);
  const showEmails = type === "USER_WITH_EMAIL";
  const showTemplates = channel === "EMAIL";
  const [templates, setTemplates] = useState<any[]>([]);

  const onConfirm = async () => {
    try {
      await form.validateFields();
      setIsOpen(true);
    } catch (error) {
      setIsOpen(false);
    }
  };

  const onSubmit = async (body: any) => {
    try {
      setIsSubmitting(true);
      await post("/mass_notifications", body);
      setIsOpen(false);
      form.resetFields();
      toast({ type: "good", message: "Mass notification sent" });
    } catch (error) {
      console.error("Error submitting form: ", error);
    } finally {
      setIsSubmitting(false);
      setIsOpen(false);
    }
  };

  const handleCancel = () => setIsOpen(false);
  const handleOk = () => {
    form.submit();
  };

  useEffect(() => {
    if (showTemplates && templates.length === 0) {
      (async () => {
        const { data } = await get<any>("/sendgrid_templates");
        setTemplates(data);
      })();
    }
  }, [templates.length, showTemplates]);

  return {
    form,
    onConfirm,
    onSubmit,
    handleCancel,
    handleOk,
    type,
    emails,
    channel,
    message,
    isOpen,
    isSubmitting,
    showTitle,
    showMessage,
    showEmails,
    showTemplates,
    templates,
  };
};

export default useForm;
