import React from "react";
import { useState } from "react";
import * as backend from "../../../../Backend";
import Vimeo from "@u-wave/react-vimeo";
import { Lot, VimeoVideo } from "../../../../generated/types";
import ConfirmationModal from "../../../ConfirmationModal";
import FormModal from "../../../FormModal";
import VimeoPlayerModal from "../../../VimeoPlayerModal";
import ImageSection from "../ImagesSection";
import Sections from "../Sections";
import ComparablesSections from "../ComparablesSection";
import { useToast } from "../../../../providers/notification.provider";
import LotSection from "../LotSection";
import MediasSection from "../MediasSection";
import { videoTag } from "../../../VideoTag";

type DetailTabsProps = {
  lot: Lot;
  onReloadLot: () => void;
};

const DetailsTab = ({ lot, onReloadLot }: DetailTabsProps) => {
  const lotId = lot.id;
  const toast = useToast();
  const [vimeoVideo, setVimeoVideo] = useState<VimeoVideo | null>(null);
  const [showFullScreenVimeo, setShowFullScreenVimeo] = useState(false);
  const [showNewFullScreenVimeo, setShowNewFullScreenVimeo] = useState(false);
  const [showMasterVimeo, setShowMasterVimeo] = useState(false);
  const [showNewMasterVimeo, setShowNewMasterVimeo] = useState(false);
  const [newVimeoForImageAt, setNewVimeoForImageAt] = useState(-1);
  const [vimeoDeleteAction, setVimeoDeleteAction] = useState<Array<any>>([
    null,
    null,
  ]);

  function updateMasterVimeo(vimeoId: string, width?: number, height?: number) {
    backend.lot
      .updateMasterVimeo(lot!.id, vimeoId, width, height)
      .then((data) => {
        setShowMasterVimeo(false);
        setShowNewMasterVimeo(false);
        lot!.masterVimeoVideo = data.masterVimeoVideo;
        toast({ type: "good", message: "Vimeo video has been updated" });
      })
      .catch((err) => {
        toast({ type: "bad", message: "Could not update the Vimeo video" });
      });
  }

  function deleteFullScreenVimeo() {
    backend.lot
      .deleteFullScreenVimeo(lot!.id)
      .then(() => {
        setVimeoDeleteAction([null, null]);
        lot!.fullScreenVimeoVideo = undefined;
        toast({ type: "good", message: "Full Screen vimeo has been deleted" });
      })
      .catch((err) => {
        toast({
          type: "bad",
          message: "Could not delete the Full Screen Vimeo video",
        });
      });
  }

  function deleteMasterVimeo() {
    backend.lot
      .deleteMasterVimeo(lot!.id)
      .then(() => {
        setVimeoDeleteAction([null, null]);
        lot!.masterVimeoVideo = undefined;
        toast({ type: "good", message: "Master video vimeo has been deleted" });
      })
      .catch((err) => {
        toast({
          type: "bad",
          message: "Could not delete the Master Vimeo video",
        });
      });
  }

  function updateFullScreenVimeo(
    vimeoId: string,
    width?: number,
    height?: number,
  ) {
    backend.lot
      .updateFullScreenVimeo(lot!.id, vimeoId, width, height)
      .then((data) => {
        setShowFullScreenVimeo(false);
        setShowNewFullScreenVimeo(false);
        lot!.fullScreenVimeoVideo = data.fullScreenVimeoVideo;
        toast({ type: "good", message: "Vimeo video has been updated" });
      })
      .catch((err) => {
        toast({ type: "bad", message: "Could not update the Vimeo video" });
      });
  }

  return (
    <>
      <div className="columns is-vcentered">
        <div className="column">
          <h1 className="title">{lot.title}</h1>
        </div>
      </div>
      <div className="box">
        <LotSection lot={lot} loadLot={onReloadLot} />
        <h2>
          Master Vimeo Video{" "}
          <span
            className="as_link"
            onClick={() => setShowMasterVimeo(!showMasterVimeo)}
          >
            {showMasterVimeo ? "Hide" : "Show"}{" "}
          </span>
          {videoTag(lot.masterVimeoVideo?.id)}
        </h2>
        {showMasterVimeo && (
          <div className="box">
            <div className="columns is-multiline">
              {lot.masterVimeoVideo && (
                <div className="box">
                  <Vimeo
                    video={lot.masterVimeoVideo.id}
                    onError={() =>
                      toast({
                        type: "bad",
                        message: `No video found with id ${lot.masterVimeoVideo?.id} found`,
                      })
                    }
                    loop={true}
                    autoplay={true}
                  />
                  <span>
                    Dimensions: {lot.masterVimeoVideo.width} x{" "}
                    {lot.masterVimeoVideo.height}
                  </span>
                </div>
              )}
            </div>
            <div className="buttons is-right">
              <button
                className="button"
                onClick={() => setShowNewMasterVimeo(true)}
              >
                Change
              </button>
              {lot.masterVimeoVideo && (
                <button
                  className="button is-danger is-light"
                  onClick={() =>
                    setVimeoDeleteAction([deleteMasterVimeo, null])
                  }
                >
                  Delete Vimeo
                </button>
              )}
            </div>
          </div>
        )}
        <hr />
        <Sections lotId={lotId} toast={toast} />

        <hr />

        <MediasSection lot={lot} loadLot={onReloadLot} />

        <hr />
        <h2>
          Full Screen Vimeo Video{" "}
          <span
            className="as_link"
            onClick={() => setShowFullScreenVimeo(!showFullScreenVimeo)}
          >
            {showFullScreenVimeo ? "Hide" : "Show"}{" "}
          </span>
          {videoTag(lot.fullScreenVimeoVideo?.id)}
        </h2>

        {showFullScreenVimeo && (
          <div className="box">
            <div className="columns is-multiline">
              {lot.fullScreenVimeoVideo && (
                <div className="box">
                  <Vimeo
                    video={lot.fullScreenVimeoVideo.id}
                    onError={() =>
                      toast({
                        type: "bad",
                        message: `No video found with id ${lot?.fullScreenVimeoVideo?.id} found`,
                      })
                    }
                    loop={true}
                    autoplay={true}
                  />
                  <span>
                    Dimensions: {lot.fullScreenVimeoVideo.width} x{" "}
                    {lot.fullScreenVimeoVideo.height}
                  </span>
                </div>
              )}
            </div>
            <div className="buttons is-right">
              <button
                className="button"
                onClick={() => setShowNewFullScreenVimeo(true)}
              >
                Change
              </button>
              {lot.fullScreenVimeoVideo && (
                <button
                  className="button is-danger is-light"
                  onClick={() =>
                    setVimeoDeleteAction([deleteFullScreenVimeo, null])
                  }
                >
                  Delete Vimeo
                </button>
              )}
            </div>
          </div>
        )}
        <hr />
        <ImageSection
          lot={lot}
          setVimeoDeleteAction={setVimeoDeleteAction}
          setVimeoVideo={setVimeoVideo}
        />

        <hr />

        <ComparablesSections
          lot={lot}
          setVimeoDeleteAction={setVimeoDeleteAction}
          setVimeoVideo={setVimeoVideo}
        />
      </div>

      <FormModal
        form={[
          { label: "Vimeo ID", inputType: "text", required: true },
          { label: "Width", inputType: "number", required: false },
          { label: "Height", inputType: "number", required: false },
        ]}
        visible={
          showNewFullScreenVimeo ||
          showNewMasterVimeo ||
          newVimeoForImageAt > -1
        }
        confirmText="Save"
        onCancel={() => {
          setShowNewFullScreenVimeo(false);
          setShowNewMasterVimeo(false);
          setNewVimeoForImageAt(-1);
        }}
        onConfirm={(vimeoId, width, height) => {
          if (showFullScreenVimeo) {
            updateFullScreenVimeo(vimeoId, +width, +height);
          } else if (showMasterVimeo) {
            updateMasterVimeo(vimeoId, +width, +height);
          }
        }}
      />

      <ConfirmationModal
        bodyText={"Are you sure you want to delete the associated Vimeo video?"}
        confirmText={"Delete"}
        visible={vimeoDeleteAction[0] !== null}
        onConfirm={() => vimeoDeleteAction[0](vimeoDeleteAction[1])}
        onCancel={() => setVimeoDeleteAction([null, null])}
      />

      <VimeoPlayerModal
        video={vimeoVideo?.id || ""}
        width={vimeoVideo?.width}
        height={vimeoVideo?.height}
        autoplay={true}
        loop={true}
        onCancel={() => setVimeoVideo(null)}
        toast={toast}
        visible={!!vimeoVideo}
      />
    </>
  );
};

export default DetailsTab;
