import ConfirmationModal from "../../../ConfirmationModal";
import React from "react";
import { put } from "../../../../utils/http";
import { useToast } from "../../../../providers/notification.provider";

const PublishModal = ({ lot, visible, onClose, onReloadLot }: any) => {
  const toast = useToast();

  const PublishText = () => {
    return (
      <>
        Are you sure you want to {lot.active ? "UNPUBLISH" : "PUBLISH"} lot?
        {!lot.active && (
          <>
            <br />
            <br />
            Be sure to:
            <br />
            ✅ Enable Mixpanel
            <br />
            ✅ Update Sendgrid email
            <br />✅ Upload Factsheet
          </>
        )}
      </>
    );
  };

  const toggleActive = async () => {
    await put(`/lots/${lot.id}/active`, { active: !lot.active });
    onReloadLot();
    onClose();
    toast({
      type: "good",
      message: `Lot ${lot.active ? "unpublished" : "published"}`,
    });
  };

  return (
    <ConfirmationModal
      bodyText={<PublishText />}
      confirmText={lot.active ? "Unpublish" : "Publish"}
      visible={visible}
      onConfirm={() => toggleActive()}
      onCancel={onClose}
    />
  );
};

export default PublishModal;
