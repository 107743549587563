import React, { useEffect, useRef, useState } from "react";
import * as backend from "../Backend";
import { getDatabase, ref, onValue, off } from "firebase/database";
import { formatDate, formatEpochTime } from "../Util";
import { User, Lot } from "../generated/types";
import { chunk } from "lodash";
import Table from "./layouts/table";
import usePagination from "../utils/usePagination";
import useFetching from "../utils/useFetching";

const bidderColumns = [
  {
    title: "User Name",
    dataIndex: ["user"],
    key: "userId",
    render: (_: any, record: any) => (
      <span>
        {record.user.firstName} {record.user.lastName}
      </span>
    ),
  },
  {
    title: "Paddle Number",
    dataIndex: ["auctionRegistration", "paddle"],
    key: "paddle",
  },
  { title: "Email", dataIndex: ["user", "email"], key: "email" },
  {
    title: "Account Name",
    dataIndex: ["auctionRegistration", "account", "name"],
    key: "accountName",
  },
];

const spectatorColumns = [
  {
    title: "User Name",
    dataIndex: ["user"],
    key: "userId",
    render: (_: any, record: any) => (
      <span>
        {record.user.firstName} {record.user.lastName}
      </span>
    ),
  },
  { title: "Email", dataIndex: ["user", "email"], key: "email" },
  {
    title: "Joined At",
    dataIndex: "joinedAt",
    key: "joinedAt",
    render: formatEpochTime,
  },
];

const eventsColumns = [
  { title: "Type", dataIndex: "type", key: "type" },
  {
    title: "At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: formatDate,
  },
  {
    title: "User",
    dataIndex: ["payload", "user"],
    key: "user",
    render: (_: any, record: any) => (
      <span>
        {record?.user?.firstName} {record?.user?.lastName}
      </span>
    ),
  },
  {
    title: "Account",
    dataIndex: ["account", "name"],
    key: "account",
  },
];

const columnsAuctionRegistrations = [
  {
    title: "Auction Mobility ID",
    dataIndex: "auctionMobilityId",
    key: "auctionMobilityId",
  },
  { title: "Account Name", dataIndex: ["account", "name"], key: "accountName" },
  { title: "Paddle", dataIndex: "paddle", key: "paddle" },
];

const eventParams = {
  type_eq: "ENTERED_BIDDING_ROOM",
};

const bidderRowKey = (record: any) => record.user?.id;
const spectatorRowKey = (record: any) => record.user?.id;

type AuctionRoomProps = {
  activeLot: Lot;
};

const AuctionRoom = ({ activeLot }: AuctionRoomProps) => {
  const [bidders, setBidders] = useState<any[]>([]);
  const [spectators, setSpectators] = useState<any[]>([]);

  const [auctionRegistrations, setAuctionRegistrations] = useState<
    any[] | null
  >(null);
  const cachedUsers = useRef<User[]>([]);

  const { page: pageEvents, onChange: onChangeEvents } = usePagination();
  const {
    data: events,
    meta,
    isLoading,
  } = useFetching(
    `/lots/${activeLot?.id}/events`,
    { page: pageEvents, ...eventParams },
    [pageEvents],
  );

  useEffect(() => {
    if (!activeLot) return;

    let refresh = setInterval(() => {
      backend.lot.registrationsUnpaginated(activeLot.id).then((data) => {
        setAuctionRegistrations(data);
      });
    }, 5000);

    return () => {
      clearInterval(refresh);
    };
  }, [activeLot]);

  useEffect(() => {
    if (!auctionRegistrations) return;

    const database = getDatabase();

    const presenceRef = ref(database, "auction-room-presence");
    const onValueChange = async (snapshot: any) => {
      let realtimeDatabaseUsers: any[] = [];

      snapshot.forEach((child: any) => {
        realtimeDatabaseUsers.push(child);
      });

      const newUsers = realtimeDatabaseUsers.filter(
        (u: any) => !cachedUsers.current.find((cu) => cu.id === u.key),
      );
      let users: User[] = [];

      if (newUsers.length) {
        const chunkedUsers = chunk(newUsers, 20);

        for (const userBlock of chunkedUsers) {
          users = users.concat(
            await backend.user.allById(userBlock.map((u: any) => u.key)),
          );
        }
      }

      const bidders: any[] = [];
      const spectators: any[] = [];

      realtimeDatabaseUsers.forEach((pu) => {
        const user =
          cachedUsers.current.find((e) => e.id === pu.key) ||
          users.find((e) => e.id === pu.key);
        const auctionRegistration = auctionRegistrations.find(
          (ar) =>
            user!.accounts.find((a: any) => a.primary && a.canBid)?.id ===
            ar.account?.id,
        );

        if (auctionRegistration) {
          bidders.push({
            joinedAt: pu.val(),
            user,
            auctionRegistration,
          });
        } else {
          spectators.push({
            joinedAt: pu.val(),
            user,
          });
        }
      });

      cachedUsers.current = cachedUsers.current.concat(users);

      setBidders(bidders.filter((b) => b.user));
      setSpectators(spectators.filter((s) => s.user));
    };

    onValue(presenceRef, onValueChange);

    return () => {
      off(presenceRef, "value", onValueChange);
    };
  }, [auctionRegistrations]);

  if (!activeLot) {
    return (
      <div>
        <progress className="progress is-small is-primary" max="100">
          15%
        </progress>
      </div>
    );
  }

  if (!activeLot.auctionLive) {
    return (
      <div>
        <h1>There is no active auction</h1>
      </div>
    );
  }

  if (!auctionRegistrations || !bidders || !spectators) {
    return (
      <div>
        <progress className="progress is-small is-primary" max="100">
          15%
        </progress>
      </div>
    );
  }

  return (
    <>
      <div className="box" style={{ backgroundColor: "lightblue" }}>
        <h2 className="subtitle">LIVE AUCTION ROOM: {activeLot.title}</h2>
        <h2 className="subtitle">Bidders</h2>
        <Table
          dataSource={bidders}
          columns={bidderColumns}
          rowKey={bidderRowKey}
        />
        <h2 className="subtitle">Spectators</h2>
        <Table
          dataSource={spectators}
          columns={spectatorColumns}
          rowKey={spectatorRowKey}
        />
      </div>
      <div className="box">
        <h2 className="subtitle">Enter Bidding Room Events</h2>
        <Table
          dataSource={events}
          columns={eventsColumns}
          rowKey="id"
          meta={meta}
          loading={isLoading}
          onChange={onChangeEvents}
        />
      </div>
      <div className="box">
        <Table
          dataSource={auctionRegistrations}
          columns={columnsAuctionRegistrations}
          rowKey="id"
        />
      </div>
    </>
  );
};

export default AuctionRoom;
