import React, { useEffect, useState } from "react";
import * as backend from "../../Backend";
import { User } from "../../generated/types";
import { useDebounce } from "@react-hook/debounce";
import ConfirmationModal from "../ConfirmationModal";
import { useToast } from "../../providers/notification.provider";
import { Link } from "../../utils/navigationUtils";
import Table from "../layouts/table";
import useFetching from "../../utils/useFetching";

const styleUserAcceptance = { cursor: "pointer", color: "#CC0F35FF" };

const STATUSES: any = {
  pending: "Pending",
  on_hold: "On Hold",
  rejected: "Rejected",
  accepted: "Accepted",
};

const UserAcceptance = ({ user, onClick }: { user: User; onClick: any }) => {
  const styleWithColor =
    user.status === "accepted" ? { color: "#6c6c6c" } : styleUserAcceptance;
  const handleClick =
    user.status === "accepted" ? () => {} : () => onClick(user);

  return (
    <p style={styleWithColor} onClick={handleClick}>
      Accept
    </p>
  );
};

const ReferralCodeCell = ({ entity }: { entity: User }) => {
  if (entity?.referralCode?.referrer?.id) {
    return (
      <Link to={`/users/${entity.referralCode.referrer.id}`}>
        {entity.referralCode?.code}
      </Link>
    );
  }

  return <div>{entity.referralCode?.code}</div>;
};

const initialParams = {
  page: 1,
  emailMatching: "",
  phoneNumberMatching: "",
  fullNameMatching: "",
  canBid: false,
};

const UsersPage = () => {
  const [params, setParams] = useState(initialParams);
  const [debouncedParams, setDebouncedParams] = useDebounce(initialParams, 200);

  const {
    data: users,
    meta,
    onLoadData,
    isLoading,
  } = useFetching("/users", debouncedParams, [
    debouncedParams.page,
    debouncedParams.emailMatching,
    debouncedParams.phoneNumberMatching,
    debouncedParams.fullNameMatching,
    debouncedParams.canBid,
  ]);

  const toast = useToast();
  const [showRemoveBidders, setShowRemoveBidders] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);

  const onClickAccept = (user: User) => {
    setCurrentUser(user);
    setShowAcceptConfirmation(true);
  };

  const onCloseAcceptance = () => {
    setCurrentUser(null);
    setShowAcceptConfirmation(false);
  };

  const clear = () => setParams(initialParams);

  const onAcceptance = async () => {
    await backend.user.accept(currentUser!);
    await onLoadData();
    onCloseAcceptance();
  };

  const columns = [
    {
      title: "",
      key: "accept",
      dataIndex: "accept",
      render: (_: any, record: User) => (
        <UserAcceptance user={record} onClick={onClickAccept} />
      ),
    },
    { title: "First Name", dataIndex: "firstName", key: "firstName" },
    { title: "Last Name", dataIndex: "lastName", key: "lastName" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => STATUSES[status],
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_: any, record: User) => record.profile?.phoneNumber,
    },
    {
      title: "Referral",
      dataIndex: "referral",
      key: "referral",
      render: (_: any, record: User) => <ReferralCodeCell entity={record} />,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      render: (_: any, record: User) => (
        <Link to={`/users/${record.id}`} className="button is-small is-primary">
          View
        </Link>
      ),
    },
  ];

  const removeBidders = async () => {
    await backend.bidders._delete();
    toast({ type: "good", message: "Removed bidding accounts" });
    setShowRemoveBidders(false);
    await onLoadData();
  };

  const onUpdateParams = (obj: any) => {
    setParams({ ...params, ...obj });
    setDebouncedParams({ ...params, ...obj });
  };

  const onChangeBiddingAccounts = () =>
    onUpdateParams({ canBid: !params.canBid });

  const onChange = (page: number) => onUpdateParams({ page });

  useEffect(() => {
    const toUpdate = {
      ...params,
      page: 1,
      emailMatching: params.emailMatching,
      phoneNumberMatching: params.phoneNumberMatching,
      fullNameMatching: params.fullNameMatching,
    };
    setDebouncedParams(toUpdate);
  }, [params.emailMatching, params.phoneNumberMatching, params.fullNameMatching]) // eslint-disable-line

  return (
    <>
      <ConfirmationModal
        bodyText={
          "Are you sure you want to remove all bidders? This will prevent anyone from bidding. Do not do this during a live auction!"
        }
        confirmText="Remove Bidders"
        visible={showRemoveBidders}
        onConfirm={removeBidders}
        onCancel={() => setShowRemoveBidders(false)}
      />
      {currentUser && (
        <ConfirmationModal
          bodyText={`Would you like to accept ${currentUser.firstName} ${currentUser.lastName}? There's no undo.`}
          confirmText="Accept"
          visible={showAcceptConfirmation}
          onConfirm={onAcceptance}
          onCancel={onCloseAcceptance}
        />
      )}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li className="is-active">
            <Link to="/features" className="as_link">
              Users
            </Link>
          </li>
        </ul>
      </nav>
      <div className="box">
        <div className="columns">
          <div className="column">
            <input
              type="email"
              className="input"
              placeholder="Email..."
              value={params.emailMatching}
              onChange={(e) =>
                setParams({ ...params, emailMatching: e.target.value })
              }
            />
          </div>

          <div className="column">
            <input
              type="phoneNumber"
              className="input"
              placeholder="Phone Number..."
              value={params.phoneNumberMatching}
              onChange={(e) =>
                setParams({ ...params, phoneNumberMatching: e.target.value })
              }
            />
          </div>

          <div className="column">
            <input
              type="fullName"
              className="input"
              placeholder="Full Name..."
              value={params.fullNameMatching}
              onChange={(e) =>
                setParams({ ...params, fullNameMatching: e.target.value })
              }
            />
          </div>

          <div
            className="column is-flex"
            style={{ justifyContent: "space-between" }}
          >
            <div style={{ marginRight: "10px" }}>
              <label className="checkbox is-flex" style={{ height: "100%" }}>
                <input
                  type="checkbox"
                  checked={params.canBid}
                  onChange={onChangeBiddingAccounts}
                />
                <span
                  className="block is-flex"
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: "5px",
                  }}
                >
                  With bidding accounts
                </span>
              </label>
            </div>
            <button className="button" onClick={clear}>
              Clear
            </button>
          </div>
        </div>
      </div>
      <Table
        dataSource={users}
        columns={columns}
        rowKey="id"
        meta={meta}
        loading={isLoading}
        onChange={onChange}
      />
      <div className="columns">
        <div className="column">
          <button
            className="button is-danger"
            onClick={() => setShowRemoveBidders(true)}
          >
            Remove all Bidders
          </button>
        </div>
      </div>
    </>
  );
};

export default UsersPage;
