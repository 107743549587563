import Actions from "./Actions";
import { Tabs } from "antd";
import DetailsTab from "./tabs/DetailTabs";
import PaddlesTab from "./tabs/PaddlesTab";
import AuctionRegistrationsTab from "./tabs/AuctionRegistrationsTab";
import EventsTab from "./tabs/EventsTab";
import React, { useCallback, useEffect, useState } from "react";
import { Lot } from "../../../generated/types";
import { get } from "../../../utils/http";
import Loading from "../../layouts/loading";

type LotPageProps = {
  id: string;
};

const LotPage = ({ id }: LotPageProps) => {
  const [lot, setLot] = useState<Lot | null>();
  const onReloadLot = useCallback(async () => {
    if (!id) {
      return;
    }

    const response = await get<Lot>(`/lots/${id}`);
    setLot(response.data);
  }, [id]);

  useEffect(() => {
    onReloadLot();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!lot) {
    return <Loading />;
  }

  const lotPageKey = lot.updatedAt.toString();

  return (
    <div key={lotPageKey}>
      <Actions lot={lot} onReloadLot={onReloadLot} />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Details" key="1">
          <DetailsTab lot={lot} onReloadLot={onReloadLot} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Paddles" key="2">
          <PaddlesTab lot={lot} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Auction Registrations" key="3">
          <AuctionRegistrationsTab lot={lot} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Events" key="4">
          <EventsTab lot={lot} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default LotPage;
