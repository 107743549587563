import React, { ChangeEvent, FC, useState } from "react";
import { useForm } from "react-hook-form";

type FormModalInput = {
  label: string;
  inputType?: string;
  inputPlaceholder?: string;
  required?: boolean;
  selectOptions?: string[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

type IProps = {
  form: Array<FormModalInput>;
  confirmText: string;
  cancelText?: string;
  visible: boolean;
  onCancel: () => void;
  onConfirm: (...args: string[]) => void;
};

const FormModal: FC<IProps> = ({
  form,
  confirmText,
  cancelText = "Cancel",
  visible,
  onConfirm,
  onCancel,
}) => {
  const { register, handleSubmit, reset } = useForm();
  const [saving, setSaving] = useState(false);

  const onCancelClicked = () => {
    reset();
    onCancel();
  };

  const onConfirmClicked = async (data: Record<string, any>) => {
    setSaving(true);
    await onConfirm(...Object.values(data));
    setSaving(false);
    reset();
  };

  return (
    <div className={`modal ${visible ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onCancelClicked} />
      <div className="modal-content">
        <form className="box" onSubmit={handleSubmit(onConfirmClicked)}>
          <div className="block">
            {form.map(
              (
                {
                  label,
                  inputType = "text",
                  inputPlaceholder = "",
                  required = false,
                  onChange,
                  selectOptions,
                },
                i,
              ) => {
                const inputName = `form-input-${i}`;
                if (inputType === "select") {
                  return (
                    <div key={label} className="field">
                      <label htmlFor={inputName}>{label}:</label>
                      <select
                        id={inputName}
                        className="input"
                        placeholder={inputPlaceholder || undefined}
                        {...register(inputName, { required: true })}
                      >
                        {selectOptions!.map((o) => (
                          <option key={o}>{o}</option>
                        ))}
                      </select>
                    </div>
                  );
                } else {
                  return (
                    <div key={label} className="field">
                      <label htmlFor={inputName}>{label}:</label>
                      <input
                        id={inputName}
                        step="1"
                        min="0"
                        className="input"
                        type={inputType}
                        placeholder={inputPlaceholder || undefined}
                        {...register(inputName, { required: true })}
                      />
                    </div>
                  );
                }
              },
            )}
          </div>
          <div className="buttons is-right block">
            <button
              className="button is-danger is-light"
              type="button"
              onClick={onCancelClicked}
            >
              {cancelText}
            </button>
            <input
              type="submit"
              disabled={saving}
              className="button is-primary"
              value={confirmText}
            />
          </div>
        </form>
      </div>
      <button className="modal-close is-large" onClick={onCancelClicked} />
    </div>
  );
};

export default FormModal;
