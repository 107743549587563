import Table from "../../../layouts/table";
import React, { useState } from "react";
import useFetching from "../../../../utils/useFetching";

const columnsMassNotifications = [
  { title: "ID", key: "id", dataIndex: "id" },
  { title: "Type", key: "type", dataIndex: "type" },
  { title: "Sent At", key: "sentAt", dataIndex: "sentAt" },
  { title: "Total", key: "totalItems", dataIndex: "totalItems" },
  {
    title: "Pending",
    key: "totalPendingItems",
    dataIndex: "totalPendingItems",
  },
  { title: "Queued", key: "totalQueuedItems", dataIndex: "totalQueuedItems" },
  {
    title: "Delivered",
    key: "totalDeliveredItems",
    dataIndex: "totalDeliveredItems",
  },
  {
    title: "Undelivered",
    key: "totalUndeliveredItems",
    dataIndex: "totalUndeliveredItems",
  },
  { title: "Failed", key: "totalFailedItems", dataIndex: "totalFailedItems" },
  { title: "Sent", key: "totalSentItems", dataIndex: "totalSentItems" },
];

const NotificationsTab = () => {
  const [pageMass, setPageMass] = useState(1);

  const {
    data: massNotifications,
    meta: massNotificationsMeta,
    isLoading: isMassNotificationsLoading,
  } = useFetching("/mass_notifications", { page: pageMass }, [pageMass]);

  const onChangeMassNotificationsPage = (page: number) => setPageMass(page);

  return (
    <Table
      dataSource={massNotifications}
      columns={columnsMassNotifications}
      rowKey="id"
      meta={massNotificationsMeta}
      onChange={onChangeMassNotificationsPage}
      loading={isMassNotificationsLoading}
    />
  );
};

export default NotificationsTab;
