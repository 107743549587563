import { Lot } from "../../../generated/types";
import { useToast } from "../../../providers/notification.provider";
import { useState } from "react";
import * as backend from "../../../Backend";
import { objectToJsonFile } from "../../../Util";
import moment from "moment-timezone";

type ActionProps = {
  lot: Lot;
  onReloadLot: () => void;
};

const useActions = ({ lot, onReloadLot }: ActionProps) => {
  const lotId = lot.id;
  const toast = useToast();
  const [showPublishLot, setShowPublishLot] = useState(false);
  const onClosePublishModal = () => setShowPublishLot(false);

  const [showActivateAuction, setShowActivateAuction] = useState(false);
  const onCloseActivateAuctionModal = () => setShowActivateAuction(false);

  const [showMoveToPastLots, setShowMoveToPastLots] = useState(false);
  const onClosePastLots = () => setShowMoveToPastLots(false);

  const [showEditLot, setShowEditLot] = useState(false);
  const [saving, setSaving] = useState(false);

  const [newMasterImage, setNewMasterImage] = useState<any>(undefined);
  const [newMasterImageWidth, setNewMasterImageWidth] =
    useState<any>(undefined);
  const [newMasterImageHeight, setNewMasterImageHeight] =
    useState<any>(undefined);

  const [showMasterImage, setShowMasterImage] = useState(false);
  const [showWebflowImage, setShowWebflowImage] = useState(false);
  const onCloseShowWebflowImage = () => setShowWebflowImage(false);

  function updateLotOnApp() {
    backend.lot
      .updateLotOnApp(lotId)
      .then(() => toast({ type: "good", message: "Update lot on App." }));
  }

  const onExportLot = () => {
    objectToJsonFile(
      lot,
      `lot-${lot?.title}-${moment().format("DD-MM-YYYY")}.json`,
    );
  };

  const updateMixpanel = () => {
    backend.lot
      .update(lotId, {
        configurationAttributes: {
          mixpanable: !lot?.configuration?.mixpanable,
        },
      })
      .then(() => {
        onReloadLot();
        toast({ type: "good", message: "Mixpanel updated" });
      });
  };

  const items = [
    {
      label: "Edit",
      key: "edit",
      onClick: () => setShowEditLot(true),
    },
    {
      label: "Update lot on App",
      key: "update-lot",
      onClick: updateLotOnApp,
    },
    {
      label: "Export Lot",
      key: "export-lot",
      onClick: onExportLot,
    },
    {
      label: "Change Master Image",
      key: "change-master-image",
      onClick: () => setShowMasterImage(true),
    },
    {
      label: "Change Webflow Image",
      key: "change-webflow-image",
      onClick: () => setShowWebflowImage(true),
    },
  ];

  if (lot.canBeAddedToPastLots) {
    items.push({
      label: "Move to Past Lots",
      key: "move-to-past-lots",
      onClick: () => setShowMoveToPastLots(true),
    });
  }

  const onOpenPublishModal = () => setShowPublishLot(true);
  const onOpenActivateAuctionModal = () => setShowActivateAuction(true);

  async function uploadMasterImage() {
    if (!newMasterImage || !newMasterImageWidth || !newMasterImageHeight) {
      return;
    }
    setSaving(true);

    await backend.lot.replaceMasterImage(lotId, newMasterImage, {
      width: newMasterImageWidth,
      height: newMasterImageHeight,
    });

    toast({ type: "good", message: "Master image replaced" });
    setShowMasterImage(false);
    setNewMasterImage(undefined);
    setSaving(false);
    onReloadLot();
  }

  return {
    showPublishLot,
    onClosePublishModal,
    showActivateAuction,
    onCloseActivateAuctionModal,
    showMoveToPastLots,
    onClosePastLots,
    showEditLot,
    setShowEditLot,
    saving,
    setNewMasterImage,
    newMasterImageWidth,
    setNewMasterImageWidth,
    newMasterImageHeight,
    setNewMasterImageHeight,
    showMasterImage,
    setShowMasterImage,
    onOpenPublishModal,
    onOpenActivateAuctionModal,
    uploadMasterImage,
    showWebflowImage,
    onCloseShowWebflowImage,
    updateMixpanel,
    items,
  };
};

export default useActions;
