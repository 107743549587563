import { User, Event, AuditedAudit } from "../../../../generated/types";
import Table from "../../../../components/layouts/table";
import { formatDateTime } from "../../../../Util";
import React, { useState } from "react";
import useFetching from "../../../../utils/useFetching";
import { Card, Space } from "antd";
import usePagination from "../../../../utils/usePagination";

type NotificationsTabProps = {
  user: User;
};

const columns = [
  {
    title: "At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value: any) => formatDateTime(value),
  },
  { title: "Type", key: "type", dataIndex: "type" },
  {
    title: "Payload",
    key: "payload",
    dataIndex: "payload",
    render: (payload: any) => JSON.stringify(payload),
  },
];

const baseParams = {
  order: "created_at",
  direction: "desc",
  typeEq: [
    "SENT_SMS_NOTIFICATION",
    "SENT_MASS_SMS_NOTIFICATION",
    "SENT_ADMIN_NOTIFICATION",
    "SENT_PUSH_NOTIFICATION",
    "SENT_MASS_PUSH_NOTIFICATION",
    "SENT_MASS_EMAIL_NOTIFICATION",
  ],
};

const NotificationsTab = ({ user }: NotificationsTabProps) => {
  const [page, setPage] = useState(1);
  const params = { ...baseParams, page };

  const { data, meta, isLoading } = useFetching<Event[]>(
    `/users/${user.id}/events`,
    params,
    [page],
  );
  const onChange = (newPage: number) => setPage(newPage);

  const title = `Notifications - ${user.fullName}`;

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Card title={title}>
        <Table
          dataSource={data}
          columns={columns}
          rowKey="id"
          meta={meta}
          onChange={onChange}
          loading={isLoading}
        />
      </Card>
    </Space>
  );
};

export default NotificationsTab;
