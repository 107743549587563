import React from "react";
import { Tag } from "antd";
import { ReferralCode } from "../../../generated/types";
import { post, del, put } from "../../../utils/http";

const ActionCell = (onLoadData: any) => (_: any, record: ReferralCode) => {
  const onChangeActive = async () => {
    await post(`/homesplashes/${record.id}/activate`);
    onLoadData();
  };

  const onDeactivate = async () => {
    await put(`/homesplashes/${record.id}`, { homesplash: { active: false } });
    onLoadData();
  };

  const onDestroy = async () => {
    await del(`/homesplashes/${record.id}`);
    onLoadData();
  };

  return (
    <>
      {!record.active && (
        <Tag
          className="cursor-pointer"
          color="default"
          onClick={onChangeActive}
        >
          Activate
        </Tag>
      )}
      {record.active && (
        <Tag className="cursor-pointer" color="default" onClick={onDeactivate}>
          De-activate
        </Tag>
      )}
      <Tag className="cursor-pointer" color="default" onClick={onDestroy}>
        Delete
      </Tag>
    </>
  );
};

export default ActionCell;
