import { useToast } from "../../../providers/notification.provider";
import { useNavigate } from "../../../utils/navigationUtils";
import { Form } from "antd";
import { post } from "../../../utils/http";

const useHomesplashesNew = () => {
  const toast = useToast();
  const [form] = Form.useForm<{
    ctaColor: string;
    textColor: string;
    imageFile: any;
  }>();
  const navigate = useNavigate();

  const onCancel = () => {
    navigate("/homesplashes");
  };

  const onSubmit = async (values: any) => {
    const homesplash = {
      ...values,
      imageFile: values.imageFile?.file as Blob,
    };

    try {
      await post("/homesplashes", { homesplash }, { isMultipart: true });
      toast({
        type: "good",
        message: "Homesplash created",
      });
      navigate("/homesplashes");
    } catch (e: any) {
      toast({
        type: "error",
        message: e?.response?.data?.message,
      });
    }
  };

  return {
    form,
    onCancel,
    onSubmit,
  };
};

export default useHomesplashesNew;
