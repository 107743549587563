import { Button, Card, Space } from "antd";
import { formatDateTime } from "../../../../../Util";
import React, { useMemo } from "react";
import {
  BidderRegistration,
  User,
  Event,
} from "../../../../../generated/types";
import { useToast } from "../../../../../providers/notification.provider";
import ActionsLayout from "../../../../layouts/ActionsLayout";
import Table from "../../../../layouts/table";
import { post } from "../../../../../utils/http";
import useFetching from "../../../../../utils/useFetching";

const BIDDER_REGISTRATION_SEND_INSTRUCTIONS =
  "BIDDER_REGISTRATION_SEND_INSTRUCTIONS";

const APPROVED_BIDDER_EMAIL_SENT_PARKING_LOT_LIVE =
  "APPROVED_BIDDER_EMAIL_SENT_PARKING_LOT_LIVE";

const APPROVED_BIDDER_EMAIL_SENT_LOT_LIVE =
  "APPROVED_BIDDER_EMAIL_SENT_LOT_LIVE";

const EVENT_SUBJECTS: any = {
  [BIDDER_REGISTRATION_SEND_INSTRUCTIONS]: "Requested instructions via email",
  [APPROVED_BIDDER_EMAIL_SENT_PARKING_LOT_LIVE]: "Approved email sent",
  [APPROVED_BIDDER_EMAIL_SENT_LOT_LIVE]: "Approved email sent",
};

const columns = [
  {
    title: "Created At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (text: any) => formatDateTime(text),
  },
  { title: "Name", key: "name", dataIndex: "name" },
  { title: "Created By", key: "createdBy", dataIndex: "createdBy" },
];

const eventParams = {
  type_eq: [
    BIDDER_REGISTRATION_SEND_INSTRUCTIONS,
    APPROVED_BIDDER_EMAIL_SENT_PARKING_LOT_LIVE,
    APPROVED_BIDDER_EMAIL_SENT_LOT_LIVE,
  ],
  page: 1,
};

type BidderRegistrationSectionProps = {
  user: User;
  bidderRegistration: BidderRegistration;
  onLoadBidderRegistration: () => void;
};

const BidderRegistrationSection = ({
  user,
  bidderRegistration,
  onLoadBidderRegistration,
}: BidderRegistrationSectionProps) => {
  const toast = useToast();
  const { data: eventsInstructions, onLoadData: onLoadEventSendInstructions } =
    useFetching<Event[]>(`/users/${user.id}/events`, eventParams, []);

  const resetBidder = async () => {
    await post(`/users/${user.id}/reset_bidder`);
    onLoadBidderRegistration();
    toast({ type: "good", message: "Bidder reset" });
  };

  const triggerApprovedEmail = async () => {
    await post(`/users/${user.id}/bidder_registration/trigger_approved_email`);
    onLoadBidderRegistration();
    onLoadEventSendInstructions();
    toast({ type: "good", message: "Approved email triggered" });
  };

  const bidderRegistrationStates = useMemo(() => {
    if (!eventsInstructions || eventsInstructions.length === 0) {
      return bidderRegistration?.states || [];
    }

    return [
      ...(bidderRegistration?.states || []),
      ...eventsInstructions.map((e) => ({
        ...e,
        name: EVENT_SUBJECTS[e.type],
        createdBy: e.payload?.createdByName,
      })),
    ].sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
  }, [bidderRegistration, eventsInstructions]);

  const goToVerifications = () => {
    window.open(
      `${process.env.REACT_APP_VERIFICATIONS_URL}/auth/sign-in?token=${user.accessToken}`,
    );
  };

  const changeBidderRegistrationState = async (newState: string) => {
    await post(`/users/${user.id}/bidder_registration/states`, {
      state: newState,
    });

    onLoadBidderRegistration();
    toast({ type: "good", message: `Bidder registration ${newState}` });
  };

  const canGoToVerifications =
    bidderRegistration?.currentState === "NOT_STARTED" ||
    bidderRegistration?.currentState === "IN_PROGRESS";

  const canReject =
    bidderRegistration.currentState === "APPROVED" ||
    bidderRegistration.currentState === "ADMIN_PENDING" ||
    bidderRegistration.currentState === "IN_PROGRESS";

  const canApprove =
    bidderRegistration.currentState === "REJECTED" ||
    bidderRegistration.currentState === "NOT_STARTED" ||
    bidderRegistration.currentState === "IN_PROGRESS" ||
    bidderRegistration.currentState === "ADMIN_PENDING";

  const Extra = () => {
    return (
      <>
        <strong>State:</strong> {bidderRegistration.currentState}
      </>
    );
  };

  const title = `Bidder Registration - ${user.fullName}`;

  return (
    <Card title={title} extra={<Extra />}>
      <Space direction="vertical" size={16} style={{ width: "100%" }}>
        <ActionsLayout>
          {canReject && (
            <Button
              variant="solid"
              color="danger"
              onClick={() => changeBidderRegistrationState("REJECTED")}
            >
              REJECT
            </Button>
          )}

          {canApprove && (
            <Button
              variant="solid"
              color="primary"
              onClick={() => changeBidderRegistrationState("APPROVED")}
            >
              APPROVE
            </Button>
          )}
        </ActionsLayout>
        <ActionsLayout>
          <Button
            variant="solid"
            color="primary"
            onClick={triggerApprovedEmail}
          >
            Trigger Approved Email
          </Button>
          <Button variant="solid" color="danger" onClick={resetBidder}>
            Reset Bidder
          </Button>
          <Button
            variant="solid"
            color="primary"
            onClick={goToVerifications}
            disabled={!canGoToVerifications}
          >
            Go to verifications
          </Button>
        </ActionsLayout>
        <Table
          dataSource={bidderRegistrationStates}
          columns={columns}
          rowKey="id"
        />
      </Space>
    </Card>
  );
};

export default BidderRegistrationSection;
