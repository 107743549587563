import { Lot } from "../../../../generated/types";
import { useToast } from "../../../../providers/notification.provider";
import React, { useState } from "react";
import { Button } from "antd";
import * as backend from "../../../../Backend";

type UpdateWebflowImageModalProps = {
  lot: Lot;
  visible: boolean;
  onClose: () => void;
  onReloadLot: () => void;
};

const UpdateWebflowImageModal = ({
  lot,
  visible,
  onClose,
  onReloadLot,
}: UpdateWebflowImageModalProps) => {
  const toast = useToast();
  const [webflowImage, setWebflowImage] = useState<any>(undefined);
  const [saving, setSaving] = useState(false);

  const onSubmit = async () => {
    if (!webflowImage) {
      toast({ type: "bad", message: "Please select a file" });
      return;
    }

    setSaving(true);
    await backend.lot.replaceWebflowImage(lot.id, webflowImage);
    toast({ type: "good", message: "Webflow image replaced" });
    setWebflowImage(undefined);
    onClose();
    setSaving(false);
    onReloadLot();
  };

  return (
    <div className={`modal ${visible ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose} />
      <div className="modal-content">
        <div className="box">
          <div>Upload new webflow image (png/jpg)</div>

          {lot.webflowImageUrl && (
            <div className="center">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src={lot.webflowImageUrl} alt="webflow" width="400px" />
            </div>
          )}

          <div>
            <input
              type="file"
              onChange={(e) => setWebflowImage(e.target.files?.[0])}
            />

            <div className="buttons is-right">
              <Button
                color="danger"
                variant="solid"
                onClick={onClose}
                disabled={saving}
                style={{ marginRight: "8px" }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="solid"
                onClick={onSubmit}
                disabled={saving}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateWebflowImageModal;
