import React from "react";

const typeOptions = [
  { label: "Audio", value: "Media::Audio" },
  { label: "PDF", value: "Media::PDF" },
  { label: "Video", value: "Media::Video" },
];

interface NewSectionProps {
  showNewSection: boolean;
  setShowNewSection: (show: boolean) => void;
  saveNewSection: () => void;
  newSection: any;
  setNewSection: (any: any) => void;
}

const NewSection: React.FC<NewSectionProps> = ({
  showNewSection,
  setShowNewSection,
  saveNewSection,
  newSection,
  setNewSection,
}) => {
  const onClose = () => {
    setNewSection({});
    setShowNewSection(false);
  };

  if (!showNewSection) return null;

  return (
    <div className={`modal ${showNewSection ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose} />
      <div className="modal-content">
        <div className="box">
          <h2 className="subtitle">Add new section</h2>
          <div className="field">
            <label className="label">Title:</label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={newSection?.title}
                onChange={(e) =>
                  setNewSection({ ...newSection, title: e.target.value })
                }
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Content:</label>
            <div className="control">
              <textarea
                className="textarea"
                value={newSection?.content}
                onChange={(e) =>
                  setNewSection({ ...newSection, content: e.target.value })
                }
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Audio url:</label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={newSection?.audioUrl}
                onChange={(e) =>
                  setNewSection({ ...newSection, audioUrl: e.target.value })
                }
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Audio Title:</label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={newSection?.audioAttributes?.title}
                onChange={(e) =>
                  setNewSection({
                    ...newSection,
                    audioAttributes: {
                      ...newSection.audioAttributes,
                      title: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          {newSection?.audioUrl && (
            <>
              <div className="field">
                <label className="label">Audio Image:</label>
                <div className="control">
                  <input
                    type="file"
                    onChange={(e) =>
                      setNewSection({
                        ...newSection,
                        audioAttributes: {
                          ...newSection.audioAttributes,
                          fileFile: e.target.files?.[0],
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Player Background Color</label>
                <input
                  type="color"
                  value={newSection?.playerAttributes?.backgroundColor}
                  onChange={(e) =>
                    setNewSection({
                      ...newSection,
                      playerAttributes: {
                        ...newSection.playerAttributes,
                        backgroundColor: e.target.value,
                      },
                    })
                  }
                />
              </div>

              <div className="field">
                <label className="label">Player Wave 1 Color</label>
                <input
                  type="color"
                  value={newSection?.playerAttributes?.waveColorOne}
                  onChange={(e) =>
                    setNewSection({
                      ...newSection,
                      playerAttributes: {
                        ...newSection.playerAttributes,
                        waveColorOne: e.target.value,
                      },
                    })
                  }
                />
              </div>

              <div className="field">
                <label className="label">Player Wave 2 Color</label>
                <input
                  type="color"
                  value={newSection?.playerAttributes?.waveColorTwo}
                  onChange={(e) =>
                    setNewSection({
                      ...newSection,
                      playerAttributes: {
                        ...newSection.playerAttributes,
                        waveColorTwo: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </>
          )}
          {newSection?.id && (
            <div className="field">
              <label className="label">Remove Audio & Player:</label>
              <input
                type="checkbox"
                checked={newSection?.destroyAudioPlayer}
                onChange={(e) =>
                  setNewSection({
                    ...newSection,
                    destroyAudioPlayer: e.target.checked,
                  })
                }
              />
            </div>
          )}
          <div className="field">
            <label className="label">Show see more:</label>
            <input
              type="checkbox"
              checked={newSection?.showSeeMore}
              onChange={(e) =>
                setNewSection({
                  ...newSection,
                  showSeeMore: e.target.checked,
                })
              }
            />
          </div>
          <div className="buttons is-right">
            <button className="button is-primary" onClick={saveNewSection}>
              Save
            </button>
          </div>
        </div>
      </div>
      <button
        className="modal-close is-large"
        onClick={() => setShowNewSection(false)}
      />
    </div>
  );
};

export default NewSection;
