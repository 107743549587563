import { User, Event, AuditedAudit } from "../../../../generated/types";
import Table from "../../../../components/layouts/table";
import { formatDateTime } from "../../../../Util";
import React, { useState } from "react";
import useFetching from "../../../../utils/useFetching";
import { Card, Space } from "antd";
import usePagination from "../../../../utils/usePagination";

const STATUSES: any = {
  1: "Pending",
  2: "On Hold",
  3: "Rejected",
  4: "Accepted",
};

type EventsTabProps = {
  user: User;
};

const columns = [
  {
    title: "At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value: any) => formatDateTime(value),
  },
  { title: "Type", key: "type", dataIndex: "type" },
  {
    title: "Payload",
    key: "payload",
    dataIndex: "payload",
    render: (payload: any) => JSON.stringify(payload),
  },
];

const auditColumns = [
  {
    title: "From",
    key: "from",
    dataIndex: "from",
    render: (_: any, audit: any) => {
      return STATUSES[audit.auditedChanges["status"]?.[0]] || "-";
    },
  },
  {
    title: "To",
    key: "to",
    dataIndex: "to",
    render: (_: any, audit: any) => {
      return (
        (typeof audit.auditedChanges["status"] === "object" &&
          STATUSES[audit.auditedChanges["status"]?.[1]]) ||
        STATUSES[audit.auditedChanges["status"]]
      );
    },
  },
  {
    title: "At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value: any) => formatDateTime(value),
  },
];

const loginColumns = [
  {
    title: "At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value: any) => formatDateTime(value),
  },
];

const appVersionsColumns = [
  {
    title: "Version",
    key: "version",
    dataIndex: "version",
    render: (_: any, audit: any) => {
      return (
        (typeof audit.auditedChanges["lastAppVersion"] === "object" &&
          audit.auditedChanges["lastAppVersion"]?.[1]) ||
        audit.auditedChanges["lastAppVersion"]
      );
    },
  },
  {
    title: "At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value: any) => formatDateTime(value),
  },
];

const baseParams = {
  order: "created_at",
  direction: "desc",
  typeNotEq: [
    "SENT_SMS_NOTIFICATION",
    "SENT_MASS_SMS_NOTIFICATION",
    "SENT_ADMIN_NOTIFICATION",
    "SENT_PUSH_NOTIFICATION",
    "SENT_MASS_PUSH_NOTIFICATION",
    "SENT_MASS_EMAIL_NOTIFICATION",
  ],
};

const EventsTab = ({ user }: EventsTabProps) => {
  const [page, setPage] = useState(1);
  const params = { ...baseParams, page };

  const { data, meta, isLoading } = useFetching<Event[]>(
    `/users/${user.id}/events`,
    params,
    [page],
  );
  const onChange = (newPage: number) => setPage(newPage);

  const { page: labelsPage, onChange: onChangeLabelsPage } = usePagination();
  const {
    data: audits,
    meta: auditsMeta,
    isLoading: auditsIsLoading,
  } = useFetching<AuditedAudit[]>(
    `/users/${user.id}/audits`,
    {
      type: "status",
      page: labelsPage,
    },
    [labelsPage],
  );

  const { page: lastLoginAtPage, onChange: onChangeLastLoginAtPage } =
    usePagination();
  const {
    data: lastLoginAtAudits,
    meta: lastLoginAtMeta,
    isLoading: lastLoginAtIsLoading,
  } = useFetching<AuditedAudit[]>(
    `/users/${user.id}/audits`,
    { type: "last_login_at", page: lastLoginAtPage },
    [lastLoginAtPage],
  );

  const { page: lastAppVersionPage, onChange: onChangeLastAppVersionPage } =
    usePagination();

  const {
    data: lastAppVersionAudits,
    meta: lastAppVersionMeta,
    isLoading: lastAppVersionIsLoading,
  } = useFetching<AuditedAudit[]>(
    `/users/${user.id}/audits`,
    { type: "last_app_version", page: lastAppVersionPage },
    [lastAppVersionPage],
  );

  const title = `Events - ${user.fullName}`;

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Card title={title}>
        <Table
          dataSource={data}
          columns={columns}
          rowKey="id"
          meta={meta}
          onChange={onChange}
          loading={isLoading}
        />
      </Card>
      <Card title="User Label Updates">
        <Table
          dataSource={audits}
          columns={auditColumns}
          rowKey="id"
          meta={auditsMeta}
          onChange={onChangeLabelsPage}
          loading={auditsIsLoading}
        />
      </Card>
      <Card title="Logins">
        <Table
          dataSource={lastLoginAtAudits}
          columns={loginColumns}
          rowKey="id"
          meta={lastLoginAtMeta}
          onChange={onChangeLastLoginAtPage}
          loading={lastLoginAtIsLoading}
        />
      </Card>
      <Card title="App Versions">
        <Table
          dataSource={lastAppVersionAudits}
          columns={appVersionsColumns}
          rowKey="id"
          meta={lastAppVersionMeta}
          onChange={onChangeLastAppVersionPage}
          loading={lastAppVersionIsLoading}
        />
      </Card>
    </Space>
  );
};

export default EventsTab;
