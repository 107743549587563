import React from "react";
import { useState } from "react";
import { User } from "../../../../generated/types";
import UserDetails from "../../../user/details";
import UserProfile from "../../../user/profile";
import { useToast } from "../../../../providers/notification.provider";
import { Button, Space } from "antd";
import ActionsLayout from "../../../layouts/ActionsLayout";
import { put } from "../../../../utils/http";

type UserDetailsProps = {
  user: User;
  onReloadUser: () => void;
};

const UserDetailsTab = ({ user, onReloadUser }: UserDetailsProps) => {
  const toast = useToast();

  const [editUserInfo, setEditUserInfo] = useState(false);
  const [userInfoUpdateRequest, setUserInfoUpdateRequest] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
    profile_attributes?: {
      phoneNumber?: string;
    };
  }>({});

  const updateUserInfo = async (updateInfo: any) => {
    try {
      await put(`/users/${user.id}`, { user: updateInfo });
      onReloadUser();
      toast({ type: "good", message: "User information successfully updated" });
    } catch (e) {
      const error = e as any;
      toast({ type: "bad", message: error?.response?.data?.message });
    }
    setEditUserInfo(false);
  };

  const onApplyChanges = () => updateUserInfo(userInfoUpdateRequest);
  const onCancel = () => setEditUserInfo(false);
  const onEditPersonalInformation = () => setEditUserInfo(true);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <ActionsLayout>
        {editUserInfo ? (
          <>
            <Button color="danger" variant="solid" onClick={onApplyChanges}>
              Apply changes
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </>
        ) : (
          <Button onClick={onEditPersonalInformation}>
            Edit personal information
          </Button>
        )}
      </ActionsLayout>
      <UserDetails
        user={user}
        editUserInfo={editUserInfo}
        setUserInfoUpdateRequest={setUserInfoUpdateRequest}
        userInfoUpdateRequest={userInfoUpdateRequest}
      />

      <UserProfile
        user={user}
        editUserInfo={editUserInfo}
        setUserInfoUpdateRequest={setUserInfoUpdateRequest}
        userInfoUpdateRequest={userInfoUpdateRequest}
      />
    </Space>
  );
};

export default UserDetailsTab;
