import moment, { Moment } from "moment-timezone";

export function formatDateTime(date: Date | null | string | undefined): string {
  return date ? moment.utc(date).local().format("MMMM Do YYYY, h:mma Z") : "";
}

export function formatEpochTime(date: number | null): string {
  return date ? moment.unix(date).utc().local().format("h:mma Z") : "";
}

export function formatDate(date: Date | null): string {
  return date ? moment.utc(date).local().format("MMMM Do YYYY") : "";
}

export function formatDateToUtc(date: Date | null): string {
  return date ? moment.utc(date).format("MMMM Do YYYY") : "";
}

export function objectToJsonFile(object: any, filename: string): void {
  const blob = new Blob([JSON.stringify(object)], { type: "application/json" });
  saveBlobToFile(filename, blob);
}

export function getTimezone(): string {
  return moment.tz.guess();
}

export function convertToMomentWithTimezone(
  date: Date | Moment | null,
  toTimezone: string,
): Moment {
  if (!date) {
    return moment();
  }
  if (!moment.tz.zone(toTimezone)) {
    throw new Error(`Invalid timezone ${toTimezone}`);
  }
  return moment(date).tz(toTimezone);
}

export function saveBlobToFile(filename: string, blob: Blob): void {
  if (window) {
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
