import React, { useState } from "react";
import * as backend from "../../Backend";

const initialForm = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "+",
  notTriggerSmsOrEmail: false,
};

const VipPage = () => {
  const [successMessage, setSuccessMessage] = useState<null | string>();
  const [user, setUser] = useState<any>({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialForm);

  const onChange = (key: string, value: any) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const onSubmit = () => {
    if (!form.firstName || !form.lastName) {
      setError("First Name and Last Name are required");
      return;
    }

    if (!form.email && (!form.phoneNumber || form.phoneNumber === "+")) {
      setError("Email or Phone Number are required");
      return;
    }

    setError("");
    setLoading(true);
    const body = {
      first_name: form.firstName,
      last_name: form.lastName,
      email: form.email,
      phone_number: form.phoneNumber === "+" ? null : form.phoneNumber,
      not_trigger_sms_or_email: form.notTriggerSmsOrEmail,
    };

    backend.user
      .createVip(body)
      .then((response) => {
        setForm(initialForm);
        setUser(response);
        setSuccessMessage("User created successfully");

        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000);
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (successMessage) {
    return (
      <div className="columns">
        <div className="column" />
        <div className="column is-half">
          <div className="notification is-success">
            {successMessage} with ID: ${user.id}
          </div>
        </div>
        <div className="column" />
      </div>
    );
  }

  return (
    <div className="columns">
      <div className="column" />
      <div className="column is-half">
        <div className="panel">
          <div className="panel-heading">
            <h3 className="title">Create new user</h3>
          </div>
        </div>

        {error && <div className="notification is-danger">{error}</div>}

        <div className="field">
          <label htmlFor="firstName" className="label">
            First Name
          </label>
          <div className="control">
            <input
              onChange={(e) => onChange("firstName", e.target.value)}
              value={form.firstName}
              placeholder="First Name"
              id="firstName"
              className="input"
              type="text"
              name="firstName"
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="lastName" className="label">
            Last Name
          </label>
          <div className="control">
            <input
              onChange={(e) => onChange("lastName", e.target.value)}
              value={form.lastName}
              placeholder="Last Name"
              id="lastName"
              className="input"
              type="text"
              name="lastName"
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="email" className="label">
            Email
          </label>
          <div className="control">
            <input
              onChange={(e) => onChange("email", e.target.value)}
              value={form.email}
              placeholder="Email"
              id="email"
              className="input"
              type="text"
              name="email"
            />
          </div>
        </div>

        <div style={{ marginBottom: "10px" }}>
          <strong>OR</strong>
        </div>

        <div className="field">
          <label htmlFor="phoneNumber" className="label">
            Phone Number
          </label>
          <div className="control">
            <input
              onChange={(e) => onChange("phoneNumber", e.target.value)}
              value={form.phoneNumber}
              placeholder="Phone Number"
              id="phoneNumber"
              className="input"
              type="text"
              name="phoneNumber"
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="notTriggerSmsOrEmail" className="checkbox">
            <input
              onChange={(e) =>
                onChange("notTriggerSmsOrEmail", e.target.checked)
              }
              id="notTriggerSmsOrEmail"
              name="notTriggerSmsOrEmail"
              type="checkbox"
              checked={form.notTriggerSmsOrEmail}
            />
            &nbsp;Do NOT trigger SMS or Email
          </label>
        </div>

        <div className="buttons is-right">
          <button
            onClick={onSubmit}
            className="button is-success"
            type="submit"
            disabled={loading}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="column" />
    </div>
  );
};

export default VipPage;
