import { User, BidderRegistration } from "../../../../../generated/types";
import React, { useCallback, useEffect, useState } from "react";
import Loading from "../../../../layouts/loading";
import { get } from "../../../../../utils/http";
import { Space } from "antd";
import ShippingAddress from "./ShippingAddress";
import FinancialVerificationAccounts from "./FinancialVerificationAccounts";
import TermsAccepted from "./TermsAccepted";
import UserSteps from "./UserSteps";
import BidderRegistrationSection from "./BidderRegistrationSection";

type BidderRegistrationTabProps = {
  user: User;
  onReloadUser: () => void;
};

const BidderRegistrationTab = ({
  user,
  onReloadUser,
}: BidderRegistrationTabProps) => {
  const [bidderRegistration, setBidderRegistration] =
    useState<BidderRegistration | null>(null);

  const canEditBidderRegistration =
    bidderRegistration?.currentState === "IN_PROGRESS" ||
    bidderRegistration?.currentState === "NOT_STARTED";

  const onLoadBidderRegistration = useCallback(async () => {
    const response = await get<BidderRegistration>(
      `/users/${user.id}/bidder_registration`,
    );
    setBidderRegistration(response.data);
  }, [user.id]);

  useEffect(() => {
    (async () => {
      await onLoadBidderRegistration();
    })();
  }, [user.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!bidderRegistration) {
    return <Loading />;
  }

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <BidderRegistrationSection
        user={user}
        bidderRegistration={bidderRegistration}
        onLoadBidderRegistration={onLoadBidderRegistration}
      />

      <UserSteps
        user={user}
        canEditBidderRegistration={canEditBidderRegistration}
      />

      <TermsAccepted
        user={user}
        onLoadBidderRegistration={onLoadBidderRegistration}
        bidderRegistration={bidderRegistration}
        canEditBidderRegistration={canEditBidderRegistration}
      />

      <ShippingAddress bidderRegistration={bidderRegistration} />

      <FinancialVerificationAccounts user={user} onReloadUser={onReloadUser} />
    </Space>
  );
};

export default BidderRegistrationTab;
