"use client";

import React, { createContext, useContext, useEffect } from "react";

export const NotificationContext = createContext<any>({
  value: null,
  setValue: (_: any) => {},
});

export const NotificationsProvider = ({ children }: any) => {
  const [value, setValue] = React.useState<any>(null);

  useEffect(() => {
    if (!!value) {
      setTimeout(() => {
        setValue(null);
      }, 5000);
    }
  }, [value]);

  return (
    <NotificationContext.Provider value={{ value, setValue }}>
      {children}

      {value && (
        <div
          id="toast"
          style={{ zIndex: 2000 }}
          className={`is-visible notification ${value?.type === "good" ? "is-primary" : "is-danger"}`}
        >
          {value?.message}
        </div>
      )}
    </NotificationContext.Provider>
  );
};

export const useToast = () => {
  const { setValue } = useContext(NotificationContext);
  return setValue;
};
