import PastLotsTable from "./PastLotsTable";
import React from "react";
import usePastLots from "./usePastLots";

const PastLotsNextPage = () => {
  const props = usePastLots();

  return (
    <>
      <div className="columns is-vcentered">
        <div className="column">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li className="is-active">
                <span className="as_link as_link_padding">Past Lots</span>
              </li>
            </ul>
          </nav>
        </div>
        <div className="column">
          <div className="buttons is-right">
            {props.reorderPastLots ? (
              <>
                <button
                  className="button is-danger is-light"
                  onClick={props.onDisableReorderPastLots}
                >
                  Cancel
                </button>
                <button
                  className="button is-primary"
                  onClick={props.updatePastLotsPositions}
                >
                  Save Ordering
                </button>
              </>
            ) : (
              <>
                <button
                  className="button"
                  onClick={props.onEnableReorderPastLots}
                >
                  Reorder
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <PastLotsTable {...props} />
    </>
  );
};

export default PastLotsNextPage;
