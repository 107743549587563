import { useCallback, useEffect, useState } from "react";
import * as backend from "../../../../Backend";
import { arrayMove } from "@dnd-kit/sortable";
import { LotsSection as Section } from "../../../../generated/types";

const useSections = (lotId: string, toast: (toast: any) => void) => {
  const [sections, setSections] = useState<null | Section[]>(null);
  const [showSections, setShowSections] = useState(false);
  const [reorderSections, setReorderSections] = useState(false);
  const [sectionPositions, setSectionPositions] = useState<{
    [key: string]: number;
  }>({});

  const [showNewSection, setShowNewSection] = useState(false);
  const [newSection, setNewSection] = useState<any>({});

  const [isDragging, setIsDragging] = useState(false);

  const loadSections = useCallback(async () => {
    const sectionsData = await backend.lot.sections(lotId);
    reorderList(sectionsData);
    setSections(sectionsData);
  }, [lotId]);

  const saveNewSection = async () => {
    try {
      const destroyAudioAndPlayer = newSection.destroyAudioPlayer;

      const body = {
        ...newSection,
        audioUrl: destroyAudioAndPlayer ? "" : newSection.audioUrl || "",
        audioAttributes: {
          ...newSection.audioAttributes,
          description: "",
          url: "",
          thumbnailUrl: "",
          ...(destroyAudioAndPlayer ? { _destroy: 1 } : {}),
        },
        playerAttributes: {
          ...newSection.playerAttributes,
          ...(destroyAudioAndPlayer ? { _destroy: 1 } : {}),
        },
      };

      if (newSection?.id) {
        await backend.lot.updateSection(lotId, newSection?.id, {
          ...body,
        });
      } else {
        await backend.lot.createSection(lotId, { ...body });
      }

      setNewSection({});
      setShowNewSection(false);
      toast({
        type: "good",
        message: "Changes made, click “Update lot on app. ",
      });
      loadSections();
    } catch (e: any) {
      toast({ type: "bad", message: e?.response?.data?.message });
    }
  };

  const onAddSection = () => {
    setNewSection({});
    setShowNewSection(true);
  };

  const onEditSection = (section: any) => {
    setNewSection({
      ...section,
      audioAttributes: { ...section.audio },
      playerAttributes: { ...section.player },
    });
    setShowNewSection(true);
  };

  const onDeleteSection = useCallback(
    async (section: any) => {
      try {
        await backend.lot.deleteSection(lotId, section.id);
        await loadSections();
        toast({ message: "section deleted", type: "success" });
      } catch (error) {}
    },
    [lotId, loadSections, toast],
  );

  const handleDragEnd = useCallback(
    async (event: any) => {
      if (!sections) return;
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = sections.findIndex(
          (section) => section.id === active.id,
        );
        const newIndex = sections.findIndex(
          (section) => section.id === over.id,
        );

        const updatedSections = arrayMove(sections, oldIndex, newIndex).map(
          (section, index) => ({
            ...section,
            position: index,
          }),
        );

        setSections(updatedSections);
        const updatedPositions = updatedSections.reduce(
          (positions, item, index) => {
            positions[item.id] = index;
            return positions;
          },
          {} as { [key: string]: number },
        );
        setSectionPositions(updatedPositions);
        setIsDragging(false);
        try {
          await backend.lot.sectionPositions(lotId, { data: updatedPositions });
        } catch (error) {}
      } else {
        setIsDragging(false);
      }
    },
    [lotId, sections],
  );

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragCancel = () => {
    setIsDragging(false);
  };

  const reorderList = (sections: any[]) => {
    setSectionPositions(
      sections.reduce((obj: any, i: any) => {
        obj[i.id] = i.position;
        return obj;
      }, {}),
    );
  };

  const saveSectionOrdering = useCallback(async () => {
    try {
      await backend.lot.sectionPositions(lotId, { data: sectionPositions });
      setReorderSections(false);
      await loadSections();
      toast({ message: "Reorder finished", type: "success" });
    } catch (error) {}
  }, [lotId, loadSections, sectionPositions, toast]);

  useEffect(() => {
    loadSections();
  }, [loadSections]);

  return {
    sections,
    showSections,
    sectionPositions,
    setSectionPositions,
    setShowSections,
    handleDragEnd,
    handleDragStart,
    handleDragCancel,
    reorderSections,
    setReorderSections,
    saveSectionOrdering,
    onEditSection,
    onDeleteSection,
    showNewSection,
    saveNewSection,
    onAddSection,
    setShowNewSection,
    isDragging,
    newSection,
    setNewSection,
  };
};

export default useSections;
