import React from "react";
import { formatDate } from "../../../../Util";
import usePagination from "../../../../utils/usePagination";
import useFetching from "../../../../utils/useFetching";
import Table from "../../../layouts/table";
import { Lot } from "../../../../generated/types";

const columns = [
  { title: "Type", dataIndex: "type", key: "type" },
  { title: "At", dataIndex: "createdAt", key: "createdAt", render: formatDate },
  { title: "User ID", dataIndex: ["user", "id"], key: "userId" },
  {
    title: "User First Name",
    dataIndex: ["user", "firstName"],
    key: "userFirstName",
  },
  {
    title: "User Last Name",
    dataIndex: ["user", "lastName"],
    key: "userLastName",
  },
];

type EventsTabProps = {
  lot: Lot;
};

const EventsTab = ({ lot }: EventsTabProps) => {
  const { page, onChange } = usePagination();
  const { data, meta, isLoading } = useFetching(
    `/lots/${lot.id}/events`,
    { page },
    [page],
  );

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      meta={meta}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default EventsTab;
