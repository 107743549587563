import Table from "../../components/layouts/table";
import useLotsTable from "./useLotsTable";
import { columns } from "./columns";

const LotsTable = () => {
  const { lots, meta, onChange, isLoading } = useLotsTable();

  return (
    <Table
      dataSource={lots}
      columns={columns}
      rowKey="id"
      meta={meta}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default LotsTable;
