import { formatDateTime } from "../../../../../Util";
import { Button, Card, Col, Flex, Row } from "antd";
import React from "react";
import { BidderRegistration, User } from "../../../../../generated/types";
import { post } from "../../../../../utils/http";

type TermsAcceptedProps = {
  user: User;
  onLoadBidderRegistration: () => void;
  bidderRegistration: BidderRegistration;
  canEditBidderRegistration: boolean;
};

const TermsAccepted = ({
  user,
  onLoadBidderRegistration,
  bidderRegistration,
  canEditBidderRegistration,
}: TermsAcceptedProps) => {
  const createConditionsOfSaleOverride = async () => {
    await post(
      `/users/${user.id}/bidder_registration/conditions_of_sale_acceptance`,
    );
    onLoadBidderRegistration();
  };

  const createExtraFeesOverride = async () => {
    await post(`/users/${user.id}/bidder_registration/extra_fees_acceptance`);
    onLoadBidderRegistration();
  };

  const canApproveConditionsOfSale =
    canEditBidderRegistration && !bidderRegistration.conditionsOfSaleAcceptedAt;
  const canApproveExtraFees =
    canEditBidderRegistration && !bidderRegistration.extraFeesAcceptedAt;

  return (
    <Card title="Terms Accepted">
      <Row gutter={[16, 8]}>
        <Col span={4}>
          <strong>Conditions of sale:</strong>
        </Col>
        <Col span={4}>
          {bidderRegistration.conditionsOfSaleAcceptedAt
            ? "APPROVED"
            : "PENDING"}
        </Col>
        <Col span={4}>
          <strong>Accepted At:</strong>
        </Col>
        <Col span={8}>
          {formatDateTime(bidderRegistration.conditionsOfSaleAcceptedAt)}
        </Col>
        <Col span={4}>
          <Flex justify="end">
            {canApproveConditionsOfSale && (
              <Button
                color="primary"
                variant="solid"
                onClick={createConditionsOfSaleOverride}
              >
                Manually Approve
              </Button>
            )}
          </Flex>
        </Col>

        <Col span={4}>
          <strong>Extra fees:</strong>
        </Col>
        <Col span={4}>
          {bidderRegistration.extraFeesAcceptedAt ? "APPROVED" : "PENDING"}
        </Col>
        <Col span={4}>
          <strong>Accepted At:</strong>
        </Col>
        <Col span={8}>
          {formatDateTime(bidderRegistration.extraFeesAcceptedAt)}
        </Col>
        <Col span={4}>
          <Flex justify="end">
            {canApproveExtraFees && (
              <Button
                color="primary"
                variant="solid"
                onClick={createExtraFeesOverride}
              >
                Manually Approve
              </Button>
            )}
          </Flex>
        </Col>

        <Col span={4}>
          <strong>Bidding limit:</strong>
        </Col>
        <Col span={4}>
          {bidderRegistration.biddingLimitEnabled ? "ACTIVE" : "WAIVED"}
        </Col>
        <Col span={4}>
          <strong>Amount:</strong>
        </Col>
        <Col span={4}>{bidderRegistration.biddingLimit}</Col>
        <Col span={8} />
      </Row>
    </Card>
  );
};

export default TermsAccepted;
