"use client";

import React from "react";
import { useParams } from "../../../utils/navigationUtils";
import LotPage from "../../../components/pages/LotPage";

const LotDetailsNextPage = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return <LotPage id={id!} />;
};

export default LotDetailsNextPage;
