import { formatDateTime } from "../../../../Util";
import { capitalize } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { User, Questionnaire } from "../../../../generated/types";
import { get } from "../../../../utils/http";
import { Card, Col, Row } from "antd";

const QUESTIONNAIRE_KEYS: any = {
  how: "How did you hear about FW / Referrals",
  job: null,
  linkedin: null,
  instagram: null,
  twitter: null,
};

type QuestionnaireTabProps = {
  user: User;
};

const renderNetwork = (url: string, username: string) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      @{username}
    </a>
  );
};

const renderAnswer = (question: any, key: string) => {
  /**
   * CHANGED BY LUIYIT
   * Now the value key is just an string
   */
  if (!question.answer && !question.value) {
    return "";
  }

  switch (question.meta.type) {
    case "MultiShortText":
      return Object.entries(question.answer).map(
        ([key, val]) => `${key}: ${val} `,
      );
    case "MultiSelect":
      return Array.isArray(question.answer)
        ? question.answer.join(", ")
        : question.answer;
    case "ShortText":
      switch (key) {
        case "twitter":
          return renderNetwork(
            `https://twitter.com/${question.value}`,
            question.value,
          );

        case "linkedin":
          return renderNetwork(
            `https://www.instagram.com/${question.value}`,
            question.value,
          );

        case "instagram":
          return renderNetwork(
            `https://www.linkedin.com/in/${question.value}`,
            question.value,
          );

        default:
          return question.answer || question.value;
      }
    default:
      return question.answer || question.value;
  }
};

const QuestionnaireTab = ({ user }: QuestionnaireTabProps) => {
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(
    null,
  );

  const title = `Questionnaire - ${user.fullName}`;

  useEffect(() => {
    (async () => {
      const response = await get<Questionnaire>(
        `users/${user.id}/questionnaire`,
      );
      setQuestionnaire(response.data);
    })();
  }, [user.id]);

  return (
    <Card title={title}>
      <Row gutter={[16, 8]}>
        {questionnaire && questionnaire.version !== "NO_QUESTIONNAIRE" ? (
          <>
            <Col span={12}>
              <strong>Completed At</strong>
            </Col>
            <Col>{formatDateTime(questionnaire?.completedAt)}</Col>
            {Object.entries(questionnaire?.data || {}).map(
              ([key, value]: any[]) => (
                <Fragment key={key}>
                  <Col span={12}>
                    <strong>
                      {QUESTIONNAIRE_KEYS[key] || capitalize(key)}
                    </strong>
                  </Col>
                  <Col key={key}>{renderAnswer(value, key)}</Col>
                </Fragment>
              ),
            )}
          </>
        ) : (
          <div>No Questionnaire</div>
        )}
      </Row>
    </Card>
  );
};

export default QuestionnaireTab;
