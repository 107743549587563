import { Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import React from "react";
import { Lot } from "../../../generated/types";
import LotForm from "../../LotForm";
import PublishModal from "./modals/PublishModal";
import ActivateAuctionLiveModal from "./modals/ActivateAuctionLiveModal";
import MoveToPastLotModal from "./modals/MoveToPastLotModal";
import useActions from "./useActions";
import UpdateWebflowImageModal from "./modals/UpdateWebflowImageModal";
import ActionsLayout from "../../layouts/ActionsLayout";

type ActionProps = {
  lot: Lot;
  onReloadLot: () => void;
};

const Actions = ({ lot, onReloadLot }: ActionProps) => {
  const {
    showPublishLot,
    onClosePublishModal,
    showActivateAuction,
    onCloseActivateAuctionModal,
    showMoveToPastLots,
    onClosePastLots,
    showEditLot,
    setShowEditLot,
    saving,
    setNewMasterImage,
    newMasterImageWidth,
    setNewMasterImageWidth,
    newMasterImageHeight,
    setNewMasterImageHeight,
    showMasterImage,
    setShowMasterImage,
    onOpenPublishModal,
    onOpenActivateAuctionModal,
    uploadMasterImage,
    showWebflowImage,
    onCloseShowWebflowImage,
    updateMixpanel,
    items,
  } = useActions({ lot, onReloadLot });

  return (
    <>
      <ActionsLayout>
        <Dropdown.Button icon={<DownOutlined />} menu={{ items }}>
          Actions
        </Dropdown.Button>
        {lot.active && (
          <Button onClick={onOpenPublishModal} color="danger" variant="solid">
            Unpublish
          </Button>
        )}
        {!lot.active && <Button onClick={onOpenPublishModal}>Publish</Button>}
        {lot.auctionLive && (
          <Button
            onClick={onOpenActivateAuctionModal}
            color="danger"
            variant="solid"
          >
            Deactive Auction
          </Button>
        )}
        {!lot.auctionLive && (
          <Button onClick={onOpenActivateAuctionModal}>Activate Auction</Button>
        )}
        <Button onClick={updateMixpanel}>
          {lot.configuration?.mixpanable
            ? "Disable Mixpanel"
            : "Enable Mixpanel"}
        </Button>
      </ActionsLayout>

      <PublishModal
        visible={showPublishLot}
        lot={lot}
        onClose={onClosePublishModal}
        onReloadLot={onReloadLot}
      />

      <ActivateAuctionLiveModal
        lot={lot}
        visible={showActivateAuction}
        onClose={onCloseActivateAuctionModal}
        onReloadLot={onReloadLot}
      />

      <MoveToPastLotModal
        lot={lot}
        visible={showMoveToPastLots}
        onClose={onClosePastLots}
        onReloadLot={onReloadLot}
      />

      <UpdateWebflowImageModal
        lot={lot}
        visible={showWebflowImage}
        onClose={onCloseShowWebflowImage}
        onReloadLot={onReloadLot}
      />

      <div className={`modal ${showMasterImage ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setShowMasterImage(false)}
        />
        <div className="modal-content">
          <div className="box">
            <div>Upload new master image (png/jpg)</div>
            <div>
              <input
                type="file"
                onChange={(e) => setNewMasterImage(e.target.files?.[0])}
              />
            </div>
            <div>
              <label>
                Width:
                <input
                  type="number"
                  value={newMasterImageWidth}
                  onChange={(e) => setNewMasterImageWidth(e.target.value)}
                />
              </label>
              <label>
                Height:
                <input
                  type="number"
                  value={newMasterImageHeight}
                  onChange={(e) => setNewMasterImageHeight(e.target.value)}
                />
              </label>
            </div>
            {saving ? (
              <div style={{ marginTop: "20px" }}>
                <progress className="progress is-small is-primary" max="100">
                  40%
                </progress>
              </div>
            ) : (
              <div className="buttons is-right">
                <button
                  className="button is-primary"
                  onClick={uploadMasterImage}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
        <button
          className="modal-close is-large"
          onClick={() => setShowMasterImage(false)}
        />
      </div>
      <div className={`modal ${showEditLot ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setShowEditLot(false)}
        />
        <div className="modal-content">
          <div className="box">
            <LotForm
              lot={lot}
              onSuccess={onReloadLot}
              onClose={() => setShowEditLot(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Actions;
