import { Space } from "antd";

const ActionsLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Space
      direction="horizontal"
      style={{ width: "100%", justifyContent: "end", marginBottom: "10px" }}
    >
      {children}
    </Space>
  );
};

export default ActionsLayout;
