import { Button, Card, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  FinancialVerification,
  PlaidsAccount,
  User,
} from "../../../../../generated/types";
import { useToast } from "../../../../../providers/notification.provider";
import usePagination from "../../../../../utils/usePagination";
import useFetching from "../../../../../utils/useFetching";
import Table from "../../../../layouts/table";
import ActionsLayout from "../../../../layouts/ActionsLayout";
import { get, post, put } from "../../../../../utils/http";

const columns = [
  {
    title: "Account ID",
    key: "id",
    dataIndex: "id",
  },
  {
    title: "Available",
    key: "available",
    dataIndex: "available",
    render: (value: any) => {
      return value ? `$${value}` : "-";
    },
  },
  {
    title: "Current",
    key: "current",
    dataIndex: "current",
  },
  {
    title: "Currency",
    key: "isoCurrencyCode",
    dataIndex: "isoCurrencyCode",
  },
];

const expandable = {
  expandedRowRender: (record: PlaidsAccount) => (
    <ul style={{ margin: 0 }}>
      {record.owners.map((owner: any, index: number) => (
        <li key={`owner-${index}`}>
          <strong>Names:</strong>&nbsp;
          {owner.names.join(", ")}&nbsp; - &nbsp;
          <strong>Emails:</strong>&nbsp;
          {owner.emails.join(", ")}&nbsp; - &nbsp;
          <strong>Phone numbers:</strong>&nbsp;
          {owner.phoneNumbers.map((p: any) => p.data).join(", ")}
        </li>
      ))}
    </ul>
  ),
  rowExpandable: (record: PlaidsAccount) => !!record.owners.length,
};

type FinancialVerificationAccountsProps = {
  user: User;
  onReloadUser: () => void;
};

const FinancialVerificationAccounts = ({
  user,
  onReloadUser,
}: FinancialVerificationAccountsProps) => {
  const toast = useToast();
  const [financialVerification, setFinancialVerification] =
    useState<FinancialVerification | null>(null);
  const { page, onChange } = usePagination();
  const {
    data: plaidAccounts,
    meta,
    isLoading,
    onLoadData,
  } = useFetching<PlaidsAccount[]>(
    `/users/${user.id}/plaids/accounts`,
    { page },
    [page],
  );

  const totalAvailableAccounts = plaidAccounts
    ?.filter((account) => account.available)
    ?.reduce((acc, account) => acc + account.available, 0);

  const syncPlaidAccounts = async () => {
    await post(`/users/${user.id}/plaids/accounts/sync`);
    toast({ type: "good", message: "Plaid accounts syncing" });
    onLoadData();
  };

  const approveFinancialVerification = async () => {
    await put(`/users/${user.id}/bidder_registration/financial_verification`, {
      financial_verification: { status: "DONE" },
    });

    onLoadFinancialVerification();
    toast({ type: "good", message: "Financial verification approved" });
  };

  const onLoadFinancialVerification = useCallback(async () => {
    try {
      const response = await get<FinancialVerification>(
        `/users/${user.id}/bidder_registration/financial_verification`,
      );

      setFinancialVerification(response.data);
    } catch (e) {
      /*Error loading*/
    }
  }, [user.id]);

  useEffect(() => {
    onLoadFinancialVerification();
  }, [user.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const Extra = () => (
    <>
      <div>
        Total Available amount:
        <strong>${totalAvailableAccounts}</strong>
      </div>
      <div>
        Status: <strong>{financialVerification?.status || "-"}</strong>
      </div>
    </>
  );

  return (
    <Card title="Financial Verification Accounts" extra={<Extra />}>
      <Space direction="vertical" size={16} style={{ width: "100%" }}>
        <ActionsLayout>
          <Button color="primary" variant="solid" onClick={syncPlaidAccounts}>
            Sync Accounts
          </Button>
          <Button
            color="primary"
            variant="solid"
            onClick={approveFinancialVerification}
          >
            Manually Approve
          </Button>
        </ActionsLayout>

        <Table
          dataSource={plaidAccounts}
          columns={columns}
          rowKey={"id"}
          expandable={expandable}
          meta={meta}
          loading={isLoading}
          onChange={onChange}
        />
      </Space>
    </Card>
  );
};

export default FinancialVerificationAccounts;
