"use client";

import React from "react";
import FeaturesPage from "../../components/pages/FeaturesPage";

const FeaturesNextPage = () => {
  return <FeaturesPage />;
};

export default FeaturesNextPage;
